import { AppHealthLogger } from '../app-health-logs/app-health-logger';

declare const navigator: any;
type BatteryManager = any;

/**
 * @description Monitors various indicators of browser throttling (user idle,
 * window minimized, timers/RAF rescheduled, etc.)
 */
export class BrowserThrottlingDetector {
  private readonly ANIMATION_THRESHOLD_MS: number = 500;
  private readonly TIMER_THRESHOLD_MS: number = 1500;
  private logger: AppHealthLogger;
  private lastRafTimestamp: number = performance.now();
  private lastTimeoutTime: number = performance.now();
  private timeoutHandle: number | null = null;
  private readonly visibilityChangeHandler: () => void;
  private readonly networkInfoChangeHandler: () => void;
  private longTaskObserver: PerformanceObserver | null = null;
  protected batteryStatus: BatteryManager | null = null;
  protected documentVisibilityState: VisibilityState | null = null; // @todo rename to `DocumentVisibilityState` after upgrading TypeScript
  protected timeoutDrift: number | null = null;
  private rafId: number;
  protected requestAnimationFrameDrift: number | null = null;

  constructor(logger?: AppHealthLogger) {
    this.visibilityChangeHandler = this.handleVisibilityChange.bind(this);
    this.networkInfoChangeHandler = this.handleNetworkInfoChange.bind(this);
    this.logger = logger;
  }

  start(): void {
    this.detectVisibilityChange();
    this.detectRafThrottling();
    this.detectTimeoutThrottling();
    this.monitorNetworkInformation();
    // this.observeLongTasks(); // @todo Output is excessive, increasing the threshold reduces output but then is it still helpful?
    this.checkBatteryStatus();
  }

  // Method 1: Page Visibility API
  private detectVisibilityChange(): void {
    this.handleVisibilityChange();
    document.addEventListener('visibilitychange', this.visibilityChangeHandler);
  }

  private handleVisibilityChange(): void {
    this.documentVisibilityState = document.visibilityState;
    if (this.documentVisibilityState === 'hidden') {
      this.logger?.info('DocumentVisibility: Page is hidden');
    } else {
      this.logger?.info('DocumentVisibility: Page is now visible');
    }
  }

  // Method 2: requestAnimationFrame Throttling
  private detectRafThrottling(): void {
    const checkThrottling = (timestamp: number): void => {
      const timeDiff: number = timestamp - this.lastRafTimestamp;
      this.lastRafTimestamp = timestamp;

      // Threshold for detecting throttling
      if (timeDiff > this.ANIMATION_THRESHOLD_MS) {
        this.requestAnimationFrameDrift = timeDiff;
        this.logger?.info(
          `Possible requestAnimationFrame throttling detected, time drift: ${timeDiff.toFixed(0)} ms`,
        );
      } else {
        this.requestAnimationFrameDrift = 0;
      }

      this.rafId = requestAnimationFrame(checkThrottling);
    };

    this.rafId = requestAnimationFrame(checkThrottling);
  }

  public stopDetectingRafThrottling(): void {
    if (this.rafId !== null) {
      cancelAnimationFrame(this.rafId);
      this.rafId = null;
    }
  }

  // Method 3: setTimeout Throttling
  private detectTimeoutThrottling(): void {
    const checkTimeoutThrottling = (): void => {
      const currentTime: number = performance.now();
      const timeDiff: number = currentTime - this.lastTimeoutTime;
      this.lastTimeoutTime = currentTime;

      // Threshold for detecting throttling
      if (timeDiff > this.TIMER_THRESHOLD_MS) {
        this.timeoutDrift = timeDiff;
        this.logger?.info(
          `Possible Timeout throttling detected, time drift: ${timeDiff.toFixed(0)} ms`,
        );
      } else {
        this.timeoutDrift = 0;
      }

      this.timeoutHandle = setTimeout(checkTimeoutThrottling, 1000);
    };

    this.timeoutHandle = setTimeout(checkTimeoutThrottling, 1000);
  }

  // Method 4: Network Information API
  private monitorNetworkInformation(): void {
    if (navigator.connection) {
      navigator.connection.addEventListener(
        'change',
        this.networkInfoChangeHandler,
      );
    } else {
      this.logger?.warning(`Network Information API is not supported.`);
    }
  }

  private handleNetworkInfoChange(): void {
    if (navigator.connection) {
      this.logger?.info(
        `Network: Connection type is now: ${navigator.connection.effectiveType}`,
      );
      this.logger?.info(
        `Network: Save data mode is now: ${navigator.connection.saveData}`,
      );
    }
  }

  // Method 5: PerformanceObserver API (Long Task Detection)
  private observeLongTasks(): void {
    this.longTaskObserver = new PerformanceObserver(
      (list: PerformanceObserverEntryList): void => {
        list.getEntries().forEach((entry: PerformanceEntry): void => {
          if (entry.duration > 50) {
            // Considered a long task
            this.logger?.info(
              `Long task detected, possibly caused by possible throttling, duration: ${entry.duration.toFixed(0)} ms`,
            );
          }
        });
      },
    );

    this.longTaskObserver.observe({ entryTypes: ['longtask'] });
  }

  // Method 6: Battery Status API
  private checkBatteryStatus(): void {
    if (!navigator.getBattery) {
      this.logger?.warning('Battery Status API is not supported.');
    }

    navigator
      .getBattery()
      .then((battery: any): void => {
        // @todo Figure out TS types for the Battery API (currently unsupported by the current TypeScript version 3.9.7)
        this.batteryStatus = battery;
        battery.addEventListener(
          'chargingchange',
          this.handleBatteryChange.bind(this),
        );
        battery.addEventListener(
          'levelchange',
          this.handleBatteryChange.bind(this),
        );
      })
      .catch((): void => {
        this.logger?.warning('Battery Status API is not supported.');
      });
  }

  private handleBatteryChange(): void {
    if (this.batteryStatus) {
      this.logger?.info(`Battery level: ${this.batteryStatus.level}`);
      this.logger?.info(`Battery charging: ${this.batteryStatus.charging}`);
    }
  }

  // Cleanup method to clear timeouts or observers if needed
  public cleanup(): void {
    // Clear setTimeout if applicable
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = null;
    }

    // Remove visibility change event listener
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChangeHandler,
    );

    // Remove network information change event listener
    if (navigator.connection) {
      navigator.connection.removeEventListener(
        'change',
        this.networkInfoChangeHandler,
      );
    }

    // Disconnect PerformanceObserver for long tasks
    if (this.longTaskObserver) {
      this.longTaskObserver.disconnect();
      this.longTaskObserver = null;
    }

    // Remove battery status event listeners if the Battery API was used
    if (this.batteryStatus) {
      this.batteryStatus.removeEventListener(
        'chargingchange',
        this.handleBatteryChange,
      );
      this.batteryStatus.removeEventListener(
        'levelchange',
        this.handleBatteryChange,
      );
      this.batteryStatus = null;
    }

    this.stopDetectingRafThrottling();
  }
}
