import { RealtimeType } from 'projects/real-trading/src/trading/repositories/realtime';

import {
  addInstrumentId,
  getSettlementDateTime,
  getTimestamp,
} from '../../../../communication/src/common/misc';
import { OrderSide } from '../../../../trading/src/trading/models/order';
import {
  DataLevel,
  QuoteSide,
  UpdateType,
} from '../../../../trading/src/trading/models/quote';
import { rti } from './messages-js/otps_proto_pool';

import OrderBook = rti.OrderBook;
import BestBidOffer = rti.BestBidOffer;
import LastTrade = rti.LastTrade;

export interface SettlementPrice {
  instrument: any;
  price: number;
  settlementDate: Date;
  timestamp: number;
}

export class RProtocolDataTransformer {
  public transformData(message: any): any {
    return message;
  }
  transformLastTrade(message: LastTrade): any[] {
    const payload = [];

    const instrument = addInstrumentId({
      symbol: message.symbol,
      exchange: message.exchange,
      contractSize: 0,
      tradingSymbol: message.symbol,
      tradingExchange: message.exchange,
    });
    /* tslint:disable:no-bitwise */
    const isBuy = message.aggressor === LastTrade.TransactionType.BUY;
    const isSell = message.aggressor === LastTrade.TransactionType.SELL;
    const hasLastTrade =
      (message.presenceBits & LastTrade.PresenceBits.LAST_TRADE) ===
      LastTrade.PresenceBits.LAST_TRADE;
    const hasNetChange =
      (message.presenceBits & LastTrade.PresenceBits.NET_CHANGE) ===
      LastTrade.PresenceBits.NET_CHANGE;
    const hasPercentChange =
      (message.presenceBits & LastTrade.PresenceBits.PRECENT_CHANGE) ===
      LastTrade.PresenceBits.PRECENT_CHANGE;
    const hasVolume =
      (message.presenceBits & LastTrade.PresenceBits.VOLUME) ===
      LastTrade.PresenceBits.VOLUME;
    const hasVWAP =
      (message.presenceBits & LastTrade.PresenceBits.VWAP) ===
      LastTrade.PresenceBits.VWAP;
    /* tslint:enable:no-bitwise */

    if (hasLastTrade) {
      payload.push({
        time: getTimestamp(message),
        type: RealtimeType.TradePrint,
        result: {
          instrument,
          side: isBuy ? OrderSide.Buy : OrderSide.Sell,
          timestamp: getTimestamp(message),
          price: message.tradePrice,
          volume: message.tradeSize,
          netChange: hasNetChange ? message.netChange : undefined,
          percentChange: hasPercentChange ? message.percentChange : undefined,
        },
      });
    }
    if (hasVolume) {
      payload.push({
        time: getTimestamp(message),
        type: RealtimeType.VolumePrint,
        result: {
          instrument,
          timestamp: getTimestamp(message),
          volume: message.volume,
        },
      });
    }
    return payload;
  }

  transformBestBidOffer(bestBidOffer: BestBidOffer): any[] {
    const payload = [];

    /* tslint:disable:no-bitwise */
    const hasBid =
      (bestBidOffer.presenceBits & BestBidOffer.PresenceBits.BID) ===
      BestBidOffer.PresenceBits.BID;
    const hasAsk =
      (bestBidOffer.presenceBits & BestBidOffer.PresenceBits.ASK) ===
      BestBidOffer.PresenceBits.ASK;
    const hasLeanPrice =
      (bestBidOffer.presenceBits & BestBidOffer.PresenceBits.LEAN_PRICE) ===
      BestBidOffer.PresenceBits.LEAN_PRICE;
    /* tslint:enable:no-bitwise */

    const instrument = addInstrumentId({
      symbol: bestBidOffer.symbol,
      exchange: bestBidOffer.exchange,
      contractSize: 0,
      tradingSymbol: bestBidOffer.symbol,
      tradingExchange: bestBidOffer.exchange,
    });

    if (hasBid) {
      payload.push({
        time: getTimestamp(bestBidOffer),
        type: RealtimeType.Quote,
        result: {
          level: DataLevel.Level1,
          timestamp: getTimestamp(bestBidOffer),
          side: QuoteSide.Bid,
          instrument,
          price: bestBidOffer.bidPrice,
          orderCount: bestBidOffer.bidOrders,
          volume: bestBidOffer.bidSize,
          updateType: UpdateType.Undefined, // equivalent of a Best Bid/Ask Quote in RAPI
        },
      });
    }
    if (hasAsk) {
      payload.push({
        time: getTimestamp(bestBidOffer),
        type: RealtimeType.Quote,
        result: {
          level: DataLevel.Level1,
          timestamp: getTimestamp(bestBidOffer),
          side: QuoteSide.Ask,
          instrument,
          price: bestBidOffer.askPrice,
          orderCount: bestBidOffer.askOrders,
          volume: bestBidOffer.askSize,
          updateType: UpdateType.Undefined, // equivalent of a Best Bid/Ask Quote in RAPI
        },
      });
    }
    return payload;
  }

  transformOrderBook(orderBookUpdate: OrderBook): any[] {
    let payload = [];
    const instrument = addInstrumentId({
      symbol: orderBookUpdate.symbol,
      exchange: orderBookUpdate.exchange,
      contractSize: 0,
      tradingSymbol: orderBookUpdate.symbol,
      tradingExchange: orderBookUpdate.exchange,
    });

    /* tslint:disable:no-bitwise */
    const hasBid =
      (orderBookUpdate.presenceBits & OrderBook.PresenceBits.BID) ===
      OrderBook.PresenceBits.BID;
    const hasAsk =
      (orderBookUpdate.presenceBits & OrderBook.PresenceBits.ASK) ===
      OrderBook.PresenceBits.ASK;
    /* tslint:enable:no-bitwise */

    const hasNoBook =
      orderBookUpdate.updateType === OrderBook.UpdateType.NO_BOOK;
    const isClearBook =
      orderBookUpdate.updateType === OrderBook.UpdateType.CLEAR_ORDER_BOOK;
    const isSnapshot =
      orderBookUpdate.updateType === OrderBook.UpdateType.SNAPSHOT_IMAGE;
    const isBegin = orderBookUpdate.updateType === OrderBook.UpdateType.BEGIN;
    const isMiddle = orderBookUpdate.updateType === OrderBook.UpdateType.MIDDLE;
    const isEnd = orderBookUpdate.updateType === OrderBook.UpdateType.END;
    const isSolo = orderBookUpdate.updateType === OrderBook.UpdateType.SOLO;

    const rapiUpdateType = isSolo
      ? UpdateType.Solo
      : isBegin
        ? UpdateType.Begin
        : isMiddle
          ? UpdateType.Middle
          : isEnd
            ? UpdateType.End
            : isSnapshot
              ? UpdateType.Snapshot
              : UpdateType.Undefined;

    if (isSolo || isBegin || isMiddle || isEnd) {
      if (hasAsk) {
        const numberOfPriceLevels = orderBookUpdate.askPrice.length;
        for (let i = 0; i < numberOfPriceLevels; i++) {
          payload.push({
            time: getTimestamp(orderBookUpdate),
            type: RealtimeType.Quote,
            result: {
              level: DataLevel.Level2,
              timestamp: getTimestamp(orderBookUpdate),
              side: QuoteSide.Ask,
              instrument,
              price: orderBookUpdate.askPrice[i],
              orderCount: orderBookUpdate.askOrders[i],
              volume: orderBookUpdate.askSize[i],
              updateType: rapiUpdateType,
            },
          });
        }
      }
      if (hasBid) {
        const numberOfPriceLevels = orderBookUpdate.bidPrice.length;
        for (let i = 0; i < numberOfPriceLevels; i++) {
          payload.push({
            time: getTimestamp(orderBookUpdate),
            type: RealtimeType.Quote,
            result: {
              level: DataLevel.Level2,
              timestamp: getTimestamp(orderBookUpdate),
              side: QuoteSide.Bid,
              instrument,
              price: orderBookUpdate.bidPrice[i],
              orderCount: orderBookUpdate.bidOrders[i],
              volume: orderBookUpdate.bidSize[i],
              updateType: rapiUpdateType,
            },
          });
        }
      }
    } else if (isSnapshot) {
      payload = [this.buildOrderBookFromSnapshot(orderBookUpdate) as any];
    }

    return payload;
  }

  buildOrderBookFromSnapshot(orderBookUpdate: rti.OrderBook): any {
    /* tslint:disable:no-bitwise */
    const hasBid =
      (orderBookUpdate.presenceBits & OrderBook.PresenceBits.BID) ===
      OrderBook.PresenceBits.BID;
    const hasAsk =
      (orderBookUpdate.presenceBits & OrderBook.PresenceBits.ASK) ===
      OrderBook.PresenceBits.ASK;
    /* tslint:enable:no-bitwise */

    const instrument = addInstrumentId({
      symbol: orderBookUpdate.symbol,
      exchange: orderBookUpdate.exchange,
      contractSize: 0,
      tradingSymbol: orderBookUpdate.symbol,
      tradingExchange: orderBookUpdate.exchange,
    });

    const orderBook = {
      id: orderBookUpdate.symbol,
      instrument,
      bids: [],
      asks: [],
    };

    if (hasAsk) {
      const numberOfPriceLevels = orderBookUpdate.askPrice.length;
      for (let i = 0; i < numberOfPriceLevels; i++) {
        orderBook.asks.push({
          side: QuoteSide.Ask,
          price: orderBookUpdate.askPrice[i],
          orderCount: orderBookUpdate.askOrders[i],
          volume: orderBookUpdate.askSize[i],
        });
      }
    }
    if (hasBid) {
      const numberOfPriceLevels = orderBookUpdate.bidPrice.length;
      for (let i = 0; i < numberOfPriceLevels; i++) {
        orderBook.bids.push({
          side: QuoteSide.Bid,
          price: orderBookUpdate.bidPrice[i],
          orderCount: orderBookUpdate.bidOrders[i],
          volume: orderBookUpdate.bidSize[i],
        });
      }
    }

    return {
      type: RealtimeType.OrderBookSnapshot,
      Type: RealtimeType.OrderBookSnapshot,
      result: orderBook,
    };
  }

  transformEndOfDayPrices(message: rti.EndOfDayPrices): any {
    const payload = [];
    const hasSettlementPrice =
      // tslint:disable-next-line:no-bitwise
      (message.presenceBits & rti.EndOfDayPrices.PresenceBits.SETTLEMENT) ===
      rti.EndOfDayPrices.PresenceBits.SETTLEMENT;

    if (hasSettlementPrice) {
      const instrument = addInstrumentId({
        symbol: message.symbol,
        exchange: message.exchange,
        contractSize: 0,
        tradingSymbol: message.symbol,
        tradingExchange: message.exchange,
      });
      const settlementDate: Date = getSettlementDateTime(
        message.settlementDate,
      );
      payload.push({
        time: getTimestamp(message),
        type: RealtimeType.Settle,
        result: <SettlementPrice>{
          instrument,
          price: message.settlementPrice,
          settlementDate,
          timestamp: settlementDate.getTime(),
        },
      });
    }

    return payload;
  }
}
