import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyBinding, KeyboardListener } from '@tradrr-app/keyboard';
import { AccountsManager, Connection } from 'accounts-manager';
import { BindUnsubscribe, IUnsubscribe } from 'base-components';
import {
  ConfirmOrderComponent,
  FormActionData,
  FormActions,
  OcoStep,
  SideOrderFormComponent,
} from 'base-order-form';
import {
  Id,
  RepositoryActionData,
  RITHMIC_INFRA_TYPE,
  RProtocolConnectionWebSocketService,
  WEB_SOCKET_TYPE,
} from 'communication';
import { ILayoutNode, LayoutNode, LayoutNodeEvent } from 'layout';
import { LazyLoadingService } from 'lazy-assets';
import { LoadingService } from 'lazy-modules';
import * as clone from 'lodash.clonedeep';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NotificationService } from 'notification';
import { NotifierService } from 'notifier';
import { createBufferedSubscription } from 'projects/communication/src/services/rprotocol/buffered-subscription';
import { rti } from 'projects/communication/src/services/rprotocol/messages-js/otps_proto_pool';
import { RProtocolOrderPlantService } from 'projects/communication/src/services/rprotocol/rprotocol-order-plant.service';
import { RProtocolTickerPlantService } from 'projects/communication/src/services/rprotocol/rprotocol-ticker-plant.service';
import { RapiConstants } from 'projects/communication/src/services/rprotocol/rprotocol.model';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { TradeIntent } from 'projects/dom/src/lib/trade-intent';
import {
  filterByAccountAndInstrument,
  filterByConnectionAndInstrument,
  filterPositions,
  RealOrdersRepository,
  RealPositionsRepository,
} from 'real-trading';
import { SettingsData, SettingsService } from 'settings';
import { IBaseTemplate, TemplatesService } from 'templates';
import { ThemesHandler } from 'themes';
import {
  compareInstruments,
  getPriceSpecs,
  IAccount,
  IConnection,
  IHistoryItem,
  InstrumentType,
  IOrder,
  IPosition,
  IQuote,
  ISession,
  isForbiddenOrder,
  Level1DataFeed,
  OHLVFeed,
  OrderAccount,
  OrderDuration,
  OrdersFeed,
  OrderSide,
  OrderStatus,
  OrderType,
  PositionsFeed,
  QuoteSide,
  UpdateType,
} from 'trading';
import {
  ConfirmModalComponent,
  CreateModalComponent,
  RenameModalComponent,
} from 'ui';
import { IWindow, WindowManagerService } from 'window-manager';

import { accountsOptions, TradeLockService } from 'src/app/components';
import { Components } from 'src/app/components/components.model';
import { environment } from 'src/environments/environment';
import { InstrumentFormatter } from '../../data-grid/src/models/formatters/instrument.formatter';
import { SettingsItems } from './chart-settings/chart-settings.component';
import { ChartSettingsService } from './chart-settings/chart-settings.service';
import {
  chartReceiveKey,
  chartSettings,
  defaultChartSettings,
  IChartSettings,
  IsAutomaticPixelPrice,
} from './chart-settings/settings';
import {
  IChartState,
  IChartTemplate,
  IIntervalOption,
  IndicatorModule,
  IntervalPeriodEnum,
  IntervalPeriodToStockChartXPeriodicityEnum,
  IPeriodToLoadOption,
  IStockChartXInstrument,
  ITogglableIntervalOptions,
  ITogglablePeriodToLoadOptions,
  PeriodsToLoadToStockChartXPeriodicityEnum,
  PeriodToLoadEnum,
  PriceStyleEnum,
} from './chart.model';
import { ChartService } from './chart.service';
import {
  Datafeed,
  IStockChartXTimeFrame,
  RithmicDatafeed,
  StockChartXPeriodicityEnum,
  StockChartXPeriodicityType,
} from './datafeed';
import { StockChartXPeriodicity } from './datafeed/TimeFrame';
import { InfoComponent } from './info/info.component';
import { IChart } from './models/chart';
import { IChartConfig } from './models/chart.config';
import {
  IScxComponentState,
  IStockChartXState,
} from './models/scx.component.state';
import {
  OrderChartMarkers,
  OrderSideMarkers,
  PositionSideMarkers,
} from './objects';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { customVolumeProfileSettings } from './volume-profile-custom-settings/volume-profile-custom-settings.component';
import {
  IVolumeTemplate,
  VolumeProfileTemplatesRepository,
} from './volume-profile-custom-settings/volume-profile-templates.repository';

declare let StockChartX: any;

const EVENTS_SUFFIX = '.scxComponent';

// tslint:disable-next-line: no-empty-interface
export interface ChartComponent extends ILayoutNode, IUnsubscribe {}

const confirmModalWidth: number = 376;
const confirmModalHeight: number = 180;

@UntilDestroy()
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  providers: [{ provide: Datafeed, useClass: RithmicDatafeed }],
})
@LayoutNode()
@BindUnsubscribe()
export class ChartComponent implements AfterViewInit, OnDestroy {
  loading: boolean;
  formatter = InstrumentFormatter.forInstrument();

  @HostBinding('class.chart-unavailable') isChartUnavailable: boolean;
  @ViewChild('chartContainer')
  chartContainer: ElementRef;
  @ViewChild(ToolbarComponent) toolbar;
  @ViewChild(SideOrderFormComponent) private _sideForm: SideOrderFormComponent;
  @ViewChild('chartForm') chartForm: SideOrderFormComponent;

  session: ISession;

  @Input() window: IWindow;

  chart: IChart;
  link: string;
  activeIndicator: any;
  private orderNotificationsSubscription: Subscription;

  get chartLink() {
    return `chart-${this.link}`;
  }

  keysStack: KeyboardListener = new KeyboardListener();

  directions = ['window-left', 'window-right'];
  currentDirection = 'window-right';
  showChartForm = true;
  enableOrderForm = false;

  get showOrderConfirm(): boolean {
    return this.settings.trading.trading.showOrderConfirm;
  }
  transformAccountLabel(item: string): string {
    return this._settingsService.transformAccountLabel(item);
  }

  set showOrderConfirm(value: boolean) {
    this.settings.trading.trading.showOrderConfirm = value;
    this.broadcastData(chartReceiveKey + this._getSettingsKey(), this.settings);
  }

  get showCancelConfirm() {
    return this.settings.trading.trading.showCancelConfirm;
  }

  set showCancelConfirm(value) {
    this.settings.trading.trading.showCancelConfirm = value;
    this.broadcastData(chartReceiveKey + this._getSettingsKey(), this.settings);
  }

  readonly _INTERVAL_PERIODS_ORDER: Map<IntervalPeriodEnum, number> = new Map([
    [IntervalPeriodEnum.AmsRevsBar, 0],
    [IntervalPeriodEnum.Seconds, 1],
    [IntervalPeriodEnum.Minutes, 2],
    [IntervalPeriodEnum.Hours, 3],
    [IntervalPeriodEnum.Days, 4],
    [IntervalPeriodEnum.Range, 5],
    [IntervalPeriodEnum.Renko, 6],
    [IntervalPeriodEnum.Volume, 7],
    [IntervalPeriodEnum.Ticks, 8],
  ]);

  readonly _PERIODS_TO_LOAD_ORDER: Map<PeriodToLoadEnum, number> = new Map([
    [PeriodToLoadEnum.Days, 0],
    [PeriodToLoadEnum.Weeks, 1],
    [PeriodToLoadEnum.Months, 2],
    [PeriodToLoadEnum.Years, 3],
  ]);

  readonly _TOGGLABLE_INTERVAL_OPTIONS: ITogglableIntervalOptions = {
    [IntervalPeriodEnum.Range]: {
      active: false,
      period: IntervalPeriodEnum.Range,
      periodicities: [StockChartXPeriodicity.RANGE],
      timeFrames: [
        {
          interval: 5,
          periodicity: StockChartXPeriodicity.RANGE,
        },
        { interval: 10, periodicity: StockChartXPeriodicity.RANGE },
        { interval: 15, periodicity: StockChartXPeriodicity.RANGE },
      ],
    },
    [IntervalPeriodEnum.Renko]: {
      active: false,
      period: IntervalPeriodEnum.Renko,
      periodicities: [StockChartXPeriodicity.RENKO],
      timeFrames: [
        {
          interval: 4,
          periodicity: StockChartXPeriodicity.RENKO,
        },
        { interval: 5, periodicity: StockChartXPeriodicity.RENKO },
        { interval: 10, periodicity: StockChartXPeriodicity.RENKO },
      ],
    },
    [IntervalPeriodEnum.Volume]: {
      active: false,
      period: IntervalPeriodEnum.Volume,
      periodicities: [StockChartXPeriodicity.VOLUME],
      timeFrames: [
        {
          interval: 1000,
          periodicity: StockChartXPeriodicity.VOLUME,
        },
        { interval: 2500, periodicity: StockChartXPeriodicity.VOLUME },
        { interval: 5000, periodicity: StockChartXPeriodicity.VOLUME },
      ],
    },
    [IntervalPeriodEnum.Ticks]: {
      active: false,
      period: IntervalPeriodEnum.Ticks,
      periodicities: [StockChartXPeriodicity.TICK],
      timeFrames: [
        { interval: 500, periodicity: StockChartXPeriodicity.TICK },
        { interval: 1000, periodicity: StockChartXPeriodicity.TICK },
        { interval: 5000, periodicity: StockChartXPeriodicity.TICK },
      ],
    },
  };

  intervalOptions: IIntervalOption[] = [
    {
      active: false,
      period: IntervalPeriodEnum.AmsRevsBar,
      periodicities: [StockChartXPeriodicity.REVS],
      timeFrames: [
        {
          interval: 4,
          periodicity: StockChartXPeriodicity.REVS,
        },
        { interval: 8, periodicity: StockChartXPeriodicity.REVS },
        { interval: 12, periodicity: StockChartXPeriodicity.REVS },
        { interval: 16, periodicity: StockChartXPeriodicity.REVS },
      ],
    },
    {
      active: false,
      period: IntervalPeriodEnum.Seconds,
      periodicities: [StockChartXPeriodicity.SECOND],
      timeFrames: [
        { interval: 30, periodicity: StockChartXPeriodicity.SECOND },
        { interval: 40, periodicity: StockChartXPeriodicity.SECOND },
      ],
    },
    {
      active: false,
      period: IntervalPeriodEnum.Minutes,
      periodicities: [StockChartXPeriodicity.MINUTE],
      timeFrames: [
        {
          interval: 1,
          periodicity: StockChartXPeriodicity.MINUTE,
        },
        {
          interval: 3,
          periodicity: StockChartXPeriodicity.MINUTE,
        },
        {
          interval: 5,
          periodicity: StockChartXPeriodicity.MINUTE,
        },
        {
          interval: 15,
          periodicity: StockChartXPeriodicity.MINUTE,
        },
        {
          interval: 30,
          periodicity: StockChartXPeriodicity.MINUTE,
        },
      ],
    },
    {
      active: false,
      period: IntervalPeriodEnum.Hours,
      periodicities: [StockChartXPeriodicity.HOUR],
      timeFrames: [
        {
          interval: 1,
          periodicity: StockChartXPeriodicity.HOUR,
        },
        {
          interval: 2,
          periodicity: StockChartXPeriodicity.HOUR,
        },
        {
          interval: 3,
          periodicity: StockChartXPeriodicity.HOUR,
        },
        {
          interval: 4,
          periodicity: StockChartXPeriodicity.HOUR,
        },
      ],
    },
    {
      active: false,
      period: IntervalPeriodEnum.Days,
      periodicities: [
        StockChartXPeriodicity.DAY,
        StockChartXPeriodicity.MONTH,
        StockChartXPeriodicity.WEEK,
        StockChartXPeriodicity.YEAR,
      ],
      timeFrames: [
        {
          interval: 1,
          periodicity: StockChartXPeriodicity.DAY,
        },
        {
          interval: 1,
          periodicity: StockChartXPeriodicity.WEEK,
        },
        {
          interval: 1,
          periodicity: StockChartXPeriodicity.MONTH,
        },
      ],
    },
  ];

  readonly _TOGGLABLE_PERIOD_TO_LOAD_OPTIONS: ITogglablePeriodToLoadOptions = {
    [PeriodToLoadEnum.Years]: {
      active: false,
      period: PeriodToLoadEnum.Years,
      periodicity: StockChartXPeriodicity.YEAR,
      timeFrames: [
        { interval: 1, periodicity: StockChartXPeriodicity.YEAR },
        { interval: 2, periodicity: StockChartXPeriodicity.YEAR },
      ],
    },
  };

  periodOptions: IPeriodToLoadOption[] = [
    {
      period: PeriodToLoadEnum.Days,
      active: false,
      periodicity: StockChartXPeriodicity.DAY,
      timeFrames: [
        { interval: 1, periodicity: StockChartXPeriodicity.DAY },
        { interval: 3, periodicity: StockChartXPeriodicity.DAY },
        { interval: 5, periodicity: StockChartXPeriodicity.DAY },
      ],
    },
    {
      period: PeriodToLoadEnum.Weeks,
      active: false,
      periodicity: StockChartXPeriodicity.WEEK,
      timeFrames: [
        { interval: 1, periodicity: StockChartXPeriodicity.WEEK },
        { interval: 2, periodicity: StockChartXPeriodicity.WEEK },
        { interval: 3, periodicity: StockChartXPeriodicity.WEEK },
      ],
    },
    {
      period: PeriodToLoadEnum.Months,
      active: false,
      periodicity: StockChartXPeriodicity.MONTH,
      timeFrames: [
        { interval: 1, periodicity: StockChartXPeriodicity.MONTH },
        { interval: 3, periodicity: StockChartXPeriodicity.MONTH },
        { interval: 6, periodicity: StockChartXPeriodicity.MONTH },
      ],
    },
  ];

  ocoStep = OcoStep.None;
  firstOcoOrder: IOrder;
  secondOcoOrder: IOrder;

  lastHistoryItem: Partial<IHistoryItem> = null;
  income: number;
  incomePercentage: number;

  showOHLV = true;
  showChanges = true;
  isToolbarVisible = true;
  private _templatesSubscription: Subscription;

  private _account: IAccount;
  settings: IChartSettings;
  connections: Connection[] = [];

  // Maps connectionId to accountId
  private _preferredAccounts = new Map<Id, Id>();
  get preferredAccounts(): Map<Id, Id> {
    return this._preferredAccounts;
  }

  set preferredAccounts(accounts: Map<Id, Id>) {
    this._preferredAccounts = accounts;
  }

  public addPreferredAccount(account: IAccount): void {
    if (this._preferredAccounts.has(account.id)) {
      return;
    }
    this._preferredAccounts.set(account.connectionId, account.id);
  }

  set account(value: IAccount) {
    if (this._account?.id === value?.id) {
      // FIX-TRAD-202: Fixes https://tradrr.atlassian.net/browse/TRAD-202
      return;
    }
    this._account = value;
    this.datafeed.changeAccount(value);
    this._updateSubscriptions();
    this.refresh();
  }

  get account() {
    return this._account;
  }

  openAccounts() {
    this.layout.addComponent({
      component: {
        name: 'accounts',
        state: {},
      },
      ...accountsOptions,
    });
  }
  get accountId() {
    return this.account?.id;
  }

  get instrument() {
    return this.chart?.instrument;
  }

  set instrument(instrument) {
    this.setInstrument(instrument);
  }

  private setInstrument(instrument, reloadChart = true) {
    if (this.chart.instrument?.id === instrument.id) return;

    this.datafeed.changeInstrument(instrument);
    this.info.clear();
    this.formatter = InstrumentFormatter.forInstrument(instrument);
    this.position = this._positionSideMarkers.items.find((item) =>
      compareInstruments(item.instrument, this.instrument),
    );
    this.chart.instrument = instrument;
    this.chart.incomePrecision = instrument.precision ?? 2;

    if (reloadChart) {
      this.refresh();
    }

    this.lastHistoryItem = null;
    this.income = null;
    this.incomePercentage = null;
    this._updateOHLVData();
    this._updateSubscriptions();
  }

  forceUpdateInstrument(instrument) {
    this.datafeed.changeInstrument(instrument);
    this.info.clear();
    this.formatter = InstrumentFormatter.forInstrument(instrument);

    if (!this.chart) return;

    this.position = this._positionSideMarkers.items.find((item) =>
      compareInstruments(item.instrument, this.instrument),
    );

    this.chart.instrument = instrument;
    this.chart.incomePrecision = instrument.precision ?? 2;

    this.refresh();

    this.lastHistoryItem = null;
    this.income = null;
    this.incomePercentage = null;
    this._updateOHLVData();
    this._updateSubscriptions();
  }

  private _loadedState$ = new BehaviorSubject<IChartState>(null);
  loadedTemplate: IChartTemplate;
  isTradingEnabled: boolean = true;
  isTradingEnabledGlobally: boolean = true;

  loadedCustomeVolumeTemplate: any;

  private _loadedChart$ = new ReplaySubject<IChart>(1);

  @ViewChild(InfoComponent)
  info: InfoComponent;

  private _pos: IPosition;

  set position(value: IPosition) {
    this._pos = value;
    if (this._sideForm) this._sideForm.position = value;

    if (value) {
      this.chart?.mainPanel.positions.forEach((p) => {
        if (p.position.id == value.id) {
          p.position.size = value.size;
          p.position.price = value.price;
        }
      });
    }
  }

  get position() {
    return this._pos;
  }

  private _orderChartMarkers: OrderChartMarkers;
  private _orderSideMarkers: OrderSideMarkers;
  private _positionSideMarkers: PositionSideMarkers;

  orders: IOrder[] = [];
  componentInstanceId = Date.now();

  get orderSideMarkers() {
    return this._orderSideMarkers.items;
  }

  get orderChartMarkers() {
    return this._orderChartMarkers.items;
  }

  @ViewChild('menu') menu: NzDropdownMenuComponent;

  contextEvent: MouseEvent;

  _customeVolumeSetting: any;
  chartTemplates: IBaseTemplate[];

  private _unprocessedOrderItems: IOrder[] = [];
  private _processedOrderItems: IOrder[] = [];
  private $exchangeOrderNotificationSubscription: Subscription;
  public $ordersFetched: BehaviorSubject<IOrder[]> = new BehaviorSubject([]);

  constructor(
    public injector: Injector,
    protected _lazyLoaderService: LazyLoadingService,
    protected _themesHandler: ThemesHandler,
    private nzContextMenuService: NzContextMenuService,
    protected datafeed: Datafeed,
    private _ordersFeed: OrdersFeed,
    private _realOrdersRepository: RealOrdersRepository,
    protected _loadingService: LoadingService,
    private _ohlvFeed: OHLVFeed,
    private _levelOneDatafeed: Level1DataFeed,
    private _positionsFeed: PositionsFeed,
    protected _notifier: NotifierService,
    private _accountsManager: AccountsManager,
    private _chartService: ChartService,
    private _chartSettingsService: ChartSettingsService,
    private _modalService: NzModalService,
    private _notificationService: NotificationService,
    private _rProtocolOrderPlantService: RProtocolOrderPlantService,
    private _rProtocolTickerPlantService: RProtocolTickerPlantService,
    private _templatesService: TemplatesService,
    private _tradeLockService: TradeLockService,
    private _windowManager: WindowManagerService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _settingsService: SettingsService,
    private _webSocketRegistryService: WebSocketRegistryService,
    private _volumeProfileTemplatesRepository: VolumeProfileTemplatesRepository,
  ) {
    this.setTabIcon('icon-widget-chart');
    this.setNavbarTitleGetter(this._getNavbarTitle.bind(this));

    this._orderChartMarkers = new OrderChartMarkers(this);
    this._orderSideMarkers = new OrderSideMarkers(this);
    this._positionSideMarkers = new PositionSideMarkers(this);

    this.onRemove(
      this._ohlvFeed.on(
        filterByConnectionAndInstrument(this, (ohlv) => this._handleOHLV(ohlv)),
      ),
      this._levelOneDatafeed.on(
        filterByConnectionAndInstrument(this, (quote) =>
          this._handleQuote(quote),
        ),
      ),
    );

    this._accountsManager.connectionsChange
      .pipe(untilDestroyed(this))
      .subscribe((connections: Connection[]): void => {
        this.connections = connections.filter(
          (connection: Connection): boolean => connection.connected,
        );
      });

    this._rProtocolOrderPlantService.$requestShowOrders
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this._unprocessedOrderItems = [];
        this._processedOrderItems = [];
      });

    this.$exchangeOrderNotificationSubscription =
      this._rProtocolOrderPlantService.$exchangeOrderNotification.subscribe(
        (orderItem: rti.ExchangeOrderNotification): void => {
          let newOrderItem: IOrder;

          const quantity: number =
            orderItem.reportType === 'fill'
              ? orderItem.fillSize
              : orderItem.quantity;
          if (
            this._isOrderItemDuplicated(orderItem) ||
            this._rProtocolOrderPlantService.isZeroQuniatityOrderError(quantity)
          ) {
            return;
          }

          newOrderItem = this._getProcessedOrderItem(orderItem);

          if (orderItem.status === RapiConstants.LINE_STATUS_COMPLETE) {
            this._realOrdersRepository.fetchShowOrderHistoryFromWebSockets(
              [this._account],
              orderItem.basketId,
            );
          }

          if (newOrderItem.description) {
            this._processedOrderItems.push(newOrderItem);
          } else {
            this._unprocessedOrderItems.push(newOrderItem);
          }
        },
      );
    this._rProtocolTickerPlantService.$responseSearchSymbolsCompleted.subscribe(
      (): void => {
        const updatedOrderItems: {
          newProcessedOrderItems: IOrder[];
          unprocessedOrderItems: IOrder[];
        } | void = this._getUpdatedOrderItems(this._unprocessedOrderItems);

        if (!updatedOrderItems) {
          return;
        }

        this._unprocessedOrderItems = updatedOrderItems.unprocessedOrderItems;
        this._processedOrderItems = [
          ...this._processedOrderItems,
          ...updatedOrderItems.newProcessedOrderItems,
        ];

        requestAnimationFrame(() => {
          this.$ordersFetched.next(this._processedOrderItems);
          this._handleOrders(this._processedOrderItems);
        });
      },
    );
    this._chartSettingsService.$chartValueScaleUpdate
      .pipe(
        untilDestroyed(this),
        filter(
          ({ componentId }): boolean => componentId === this._getSettingsKey(),
        ),
        distinctUntilChanged(),
      )
      .subscribe(({ oldValueScale, newValueScale }): void => {
        this._handleChartScaleSettings(oldValueScale, newValueScale);
      });
    this._settingsService.settings
      .pipe(take(1))
      .subscribe((settings: SettingsData): void => {
        this._handleIntervalAvailability(
          IntervalPeriodEnum.Range,
          settings?.admin?.betaFeatures?.chartIntervals?.range,
        );
        this._handleIntervalAvailability(
          IntervalPeriodEnum.Renko,
          settings?.admin?.betaFeatures?.chartIntervals?.renko,
        );
        this._handleIntervalAvailability(
          IntervalPeriodEnum.Volume,
          settings?.admin?.betaFeatures?.chartIntervals?.volume,
        );
        this._handleIntervalAvailability(
          IntervalPeriodEnum.Ticks,
          settings?.admin?.betaFeatures?.chartIntervals?.ticks,
        );
        this._handlePeriodToLoadAvailability(
          PeriodToLoadEnum.Years,
          settings?.admin?.betaFeatures?.chartPeriodsToLoad?.years,
        );
      });
    this._settingsService.rangeIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((rangeIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          IntervalPeriodEnum.Range,
          rangeIntervalEnabled,
        );
      });
    this._settingsService.renkoIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((renkoIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          IntervalPeriodEnum.Renko,
          renkoIntervalEnabled,
        );
      });
    this._settingsService.volumeIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((volumeIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          IntervalPeriodEnum.Volume,
          volumeIntervalEnabled,
        );
      });
    this._settingsService.ticksIntervalAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((ticksIntervalEnabled: boolean): void => {
        this._handleIntervalAvailability(
          IntervalPeriodEnum.Ticks,
          ticksIntervalEnabled,
        );
      });
    this._settingsService.yearsPeriodToLoadAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((yearsPeriodToLoadEnabled: boolean): void => {
        this._handlePeriodToLoadAvailability(
          PeriodToLoadEnum.Years,
          yearsPeriodToLoadEnabled,
        );
      });
  }

  fetchOrders(): void {
    const requestId: string = `RequestShowOrdersID: ${Date.now()}`;

    this._realOrdersRepository.fetchOrdersFromWebSockets(
      [this.account] as unknown as OrderAccount[],
      [requestId],
    );
  }

  private _isOrderItemDuplicated(
    orderItem: rti.IExchangeOrderNotification,
  ): boolean {
    const processedOrderItem: IOrder | null = this._processedOrderItems.find(
      (processedOrderItem: IOrder): boolean =>
        processedOrderItem.id === orderItem.exchangeOrderId,
    );
    const unprocessedOrderItem: IOrder | null =
      this._unprocessedOrderItems.find(
        (unprocessedOrderItem: IOrder): boolean =>
          unprocessedOrderItem.id === orderItem.exchangeOrderId,
      );

    return processedOrderItem || unprocessedOrderItem ? true : false;
  }

  private _getUpdatedOrderItems(orderItemsToBeUpdated: IOrder[]): {
    newProcessedOrderItems: IOrder[];
    unprocessedOrderItems: IOrder[];
  } | void {
    let unprocessedOrderItems: IOrder[];
    let newProcessedOrderItems: IOrder[] = [];

    if (!orderItemsToBeUpdated.length) {
      return;
    }

    unprocessedOrderItems = orderItemsToBeUpdated.filter(
      (orderItem: IOrder): boolean => {
        const symbolData: rti.ResponseSearchSymbols =
          this._getSymbolDataForWebSocketOrderItem(orderItem.instrument.symbol);

        orderItem.description = symbolData?.symbolName;
        orderItem.instrument.description = symbolData?.symbolName;

        if (orderItem.description) {
          newProcessedOrderItems.push(orderItem);
          return false;
        }

        return true;
      },
    );

    return {
      newProcessedOrderItems,
      unprocessedOrderItems,
    };
  }

  private _getSymbolDataForWebSocketOrderItem(
    symbol: string,
  ): rti.ResponseSearchSymbols | null {
    const storedSymbolItem: rti.ResponseSearchSymbols =
      this._rProtocolTickerPlantService.$symbols
        .getValue()
        .find(
          (symbolItem: rti.ResponseSearchSymbols): boolean =>
            symbolItem.symbol === symbol,
        );

    if (storedSymbolItem) {
      return storedSymbolItem;
    }

    this._fetchSymbol(symbol);

    return null;
  }

  private _fetchSymbol(symbol: string): void {
    const tickerPlantWebSocket: RProtocolConnectionWebSocketService = <
      RProtocolConnectionWebSocketService
    >this._webSocketRegistryService.get(
      this._accountsManager.getFirstActiveConnection(),
      WEB_SOCKET_TYPE.RPROTOCOL,
      RITHMIC_INFRA_TYPE.TICKER_PLANT,
    );

    tickerPlantWebSocket.requestSearchSymbol(symbol);
  }

  handleToggleVisibility(visible): void {
    if (this.chart) this.chart.shouldDraw = visible;
    if (visible) {
      this.chart?.setNeedsLayout();
      this.chart?.setNeedsUpdate();
    }
  }

  toggleToolbarVisibility() {
    this.toolbar?.toggleToolbarVisibility();
    this.isToolbarVisible = this.toolbar?.isToolbarVisible();
  }

  setToolbarVisibility(value: boolean) {
    this.isToolbarVisible = value;
    this.toolbar?.setToolbarVisibility(value);
  }

  private _updateSubscriptions(): void {
    const connectionId = this.account?.connectionId;
    const instrument = this.instrument;
    if (connectionId != null && instrument != null) {
      this._ohlvFeed.subscribe(instrument, connectionId);
      this._levelOneDatafeed.subscribe(instrument, connectionId);
      this.unsubscribe(() => {
        this._ohlvFeed.unsubscribe(instrument, connectionId);
        this._levelOneDatafeed.unsubscribe(instrument, connectionId);
      });
    }
  }

  createCustomVolumeProfile(template: IVolumeTemplate = null): void {
    const indicator = new StockChartX.CustomVolumeProfile();

    this.chart.addIndicators(indicator);
    indicator.start();

    if (template?.settings) {
      indicator.settings = template.settings;
      indicator.templateId = template.id;
    }

    this.chart.setNeedsUpdate();
  }

  removeCustomeVolumeProfile(): void {
    this.chart.removeIndicators(this.activeIndicator);
    this.chart.setNeedsUpdate();
  }

  private _updateOrderStatus(
    rithmicOrderNotificationsWithCompletionReason: rti.RithmicOrderNotification[],
  ): void {
    rithmicOrderNotificationsWithCompletionReason.forEach(
      (
        rithmicOrderNotificationWithCompletionReason: rti.RithmicOrderNotification,
      ) => {
        this._processedOrderItems.forEach(
          (processedOrderItem: IOrder, index: number): void => {
            if (
              processedOrderItem.id !==
              rithmicOrderNotificationWithCompletionReason.exchangeOrderId
            ) {
              return;
            }

            this._processedOrderItems[index].status =
              this._getStatusForOrderItem(
                RapiConstants.LINE_STATUS_COMPLETE,
                rithmicOrderNotificationWithCompletionReason.completionReason,
              );
          },
        );
        this._unprocessedOrderItems.forEach(
          (unprocessedOrderItem: IOrder, index: number): void => {
            if (
              unprocessedOrderItem.id !==
              rithmicOrderNotificationWithCompletionReason.exchangeOrderId
            ) {
              return;
            }

            this._unprocessedOrderItems[index].status =
              this._getStatusForOrderItem(
                RapiConstants.LINE_STATUS_COMPLETE,
                rithmicOrderNotificationWithCompletionReason.completionReason,
              );
          },
        );
      },
    );

    requestAnimationFrame(() => {
      this.$ordersFetched.next(this._processedOrderItems);
    });
  }

  private _getProcessedOrderItem(
    unprocessedOrderItem: rti.IExchangeOrderNotification,
  ): IOrder {
    const symbolData: rti.ResponseSearchSymbols =
      this._getSymbolDataForWebSocketOrderItem(unprocessedOrderItem.symbol);

    return {
      account: {
        fcmId: unprocessedOrderItem.fcmId,
        ibId: unprocessedOrderItem.ibId,
        id: unprocessedOrderItem.accountId,
        name: this._accountsManager.getAccountById(
          unprocessedOrderItem.accountId,
        ).name,
      },
      accountId: unprocessedOrderItem.accountId,
      averageFillPrice: unprocessedOrderItem.avgFillPrice,
      currentSequenceNumber: unprocessedOrderItem.corSequenceNumber,
      description: symbolData?.symbolName,
      duration: <OrderDuration>(
        this._capitalize(
          rti.ExchangeOrderNotification.Duration[unprocessedOrderItem.duration],
        )
      ),
      exchange: unprocessedOrderItem.exchange,
      filledQuantity: unprocessedOrderItem.fillSize,
      /**
       * @todo Find out if 'iceQuantit' is returned in any place from R-Protocol.
       */
      iceQuantity: null,
      id: unprocessedOrderItem.basketId,
      instrument: {
        /**
         * @todo Find out if 'contractSize' is returned in any place from R-Protocol.
         */
        contractSize: Math.random(),
        description: symbolData?.symbolName,
        exchange: unprocessedOrderItem.exchange,
        id: `${unprocessedOrderItem.symbol}.${unprocessedOrderItem.exchange}`,
        /**
         * @todo Find out if 'increment' is returned in any place from R-Protocol.
         */
        increment: Math.random(),
        /**
         * @todo Find out if 'precision' is returned in any place from R-Protocol.
         */
        precision: Math.random(),
        /**
         * @todo Find out if 'productCode' is returned in any place from R-Protocol.
         */
        productCode: '',
        stringTypeRepresentation: unprocessedOrderItem.instrumentType,
        symbol: unprocessedOrderItem.symbol,
        tradingExchange: unprocessedOrderItem.exchange,
        tradingSymbol: unprocessedOrderItem.symbol,
        type: <InstrumentType>unprocessedOrderItem.instrumentType,
        tickSize: Math.random(),
      },
      price: unprocessedOrderItem.price,
      quantity: unprocessedOrderItem.quantity,
      side: <OrderSide>(
        this._capitalize(
          rti.ExchangeOrderNotification.TransactionType[
            unprocessedOrderItem.transactionType
          ],
        )
      ),
      status: this._getStatusForOrderItem(unprocessedOrderItem.status),
      symbol: unprocessedOrderItem.symbol,
      timestamp: parseInt(
        `${unprocessedOrderItem.ssboe}${('' + unprocessedOrderItem.usecs).substring(0, 3)}`,
      ),
      triggerPrice: unprocessedOrderItem.triggerPrice,
      type: this._getOrderTypeForProcessedOrderItem(
        unprocessedOrderItem.priceType,
      ),
    };
  }

  private _getOrderTypeForProcessedOrderItem(
    type: rti.ExchangeOrderNotification.PriceType,
  ): OrderType {
    switch (rti.ExchangeOrderNotification.PriceType[type]) {
      case 'LIMIT':
        return OrderType.Limit;
      case 'MARKET':
        return OrderType.Market;
      case 'STOP_LIMIT':
        return OrderType.StopLimit;
      case 'STOP_MARKET':
        return OrderType.StopMarket;
    }
  }

  private _capitalize(value: string): string {
    const lowercase: string = value.toLowerCase();
    return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
  }

  private _getStatusForOrderItem(
    status: string,
    completionReason?: string,
  ): OrderStatus {
    switch (true) {
      case !status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_CANCEL_FAILED === status:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_CANCEL_PENDING === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_CANCEL_RCVD_BY_EXCH_GWAY === status:
        return OrderStatus.Canceled;
      case RapiConstants.LINE_STATUS_CANCEL_RECEIVED === status:
        return OrderStatus.Canceled;
      case RapiConstants.LINE_STATUS_CANCEL_SENT_TO_EXCH === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_FILL:
        return OrderStatus.Filled;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_PFBC:
        return OrderStatus.Canceled;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_REJECT:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_FAILURE:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_CANCEL:
        return OrderStatus.Canceled;
      case RapiConstants.LINE_STATUS_COMPLETE === status && !completionReason:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_MOD_TRIGGER_PENDING === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_MODIFIED === status:
        return OrderStatus.New;
      case RapiConstants.LINE_STATUS_MODIFY_FAILED === status:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_MODIFY_PENDING === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_MODIFY_RCVD_BY_EXCH_GWAY === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_MODIFY_RECEIVED === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_MODIFY_SENT_TO_EXCH === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_OPEN === status:
        return OrderStatus.New;
      case RapiConstants.LINE_STATUS_OPEN_PENDING === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_ORDER_RCVD_BY_EXCH_GWAY === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_ORDER_RECEIVED === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_ORDER_SENT_TO_EXCH === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_PARTIAL === status:
        return OrderStatus.PartialFilled;
      case RapiConstants.LINE_STATUS_TRIGGER_PENDING === status:
        return OrderStatus.Pending;
      default:
        return OrderStatus.Rejected;
    }
  }

  private _requestTradeRoutes(): void {
    const subscriberId: string = this.constructor['ɵcmp'].id;
    this._rProtocolOrderPlantService.subscribeTradeRoutes(
      this._getOrderPlantWebSocket(),
      subscriberId,
    );
  }

  async ngAfterViewInit(): Promise<void> {
    this.window = this._windowManager.getWindowByComponent(this);

    this.loadFiles()
      .then(() => this.loadIndicators())
      .then(() => this.loadChart())
      .catch((e) => console.error(e));

    this._tradeLockService.$isTradingGloballyEnabled
      .pipe(untilDestroyed(this))
      .subscribe((isTradingGloballyEnabled: boolean): void => {
        this.isTradingEnabledGlobally = isTradingGloballyEnabled;
        this._changeDetectorRef.detectChanges();
      });

    this._templatesSubscription = this._templatesService.subscribe(
      (data: RepositoryActionData<IBaseTemplate>): void => {
        this.chartTemplates = (data?.items || []).filter(
          (template: IBaseTemplate): boolean =>
            template.type === Components.Chart,
        );
        if (this.loadedTemplate) {
          this.loadedTemplate = data.items.find(
            (template: IBaseTemplate): boolean =>
              this.loadedTemplate.id === template.id,
          );
        }
      },
    );

    this.chartForm?.loadState(this.settings.trading.orderArea as any);

    this.forceUpdateInstrument(this.instrument);
    this._loadTemplateList();
    this._subscribeToHotKey();
    this.onRemove(
      this._positionsFeed.on(
        filterPositions(this, (pos: IPosition): void =>
          this.handlePosition(pos),
        ),
      ),
      this._ordersFeed.on(
        filterByAccountAndInstrument(this, (order: IOrder) => {
          this._handleOrders([order]);
        }),
      ),
    );

    this.orderNotificationsSubscription = createBufferedSubscription(
      this._rProtocolOrderPlantService
        .$rithmicOrderNotificationsWithCompletionReason,
      (bufferedOrderNotifications: rti.RithmicOrderNotification[]) => {
        this._updateOrderStatus(bufferedOrderNotifications);
        this.orderNotificationsSubscription?.unsubscribe();

        this.orderNotificationsSubscription =
          this._rProtocolOrderPlantService.$rithmicOrderNotificationsWithCompletionReason.subscribe(
            (orderNotifications: rti.RithmicOrderNotification[]) => {
              this._updateOrderStatus(orderNotifications);
            },
          );
      },
    );
  }

  // @todo refactor; method copied from DOM
  private _handleOrders(orders: IOrder[]) {
    for (const order of orders) {
      if (order.account.id !== this.account?.id) {
        continue;
      }

      if (order.instrument?.id !== this.instrument?.id) {
        continue;
      }
      this._fillOrders(order);
    }

    this._changeDetectorRef.detectChanges();
  }

  // @todo refactor; method copied from DOM
  private _fillOrders(order) {
    if (isForbiddenOrder(order)) {
      this.orders = this.orders.filter((item) => item.id !== order.id);
      return;
    }

    const index = this.orders.findIndex((item) => item.id === order.id);

    if (!this.orders.length || index === -1)
      this.orders = [...this.orders, order];
    else {
      this.orders[index] = order;
      this.orders = [...this.orders];
    }
  }

  handlePosition(pos: IPosition): void {
    if (!compareInstruments(this.instrument, pos.instrument)) {
      return;
    }

    this.position = RealPositionsRepository.transformPosition(pos);
  }

  _updateOHLVData = () => {
    if (this.lastHistoryItem?.netChange) {
      this.income = this.lastHistoryItem.netChange;
    }
    if (this.lastHistoryItem?.percentChange) {
      this.incomePercentage = this.lastHistoryItem.percentChange;
    }
    this.chart &&
      this.chart.updateOHLVData({
        volume: this.lastHistoryItem?.volume,
        high: this.lastHistoryItem?.high,
        low: this.lastHistoryItem?.low,
        open: this.lastHistoryItem?.open,
        income: this.formatter.format(this.income),
        incomePercentage: this.incomePercentage,
      });
  };

  protected loadFiles(): Promise<any> {
    return this._lazyLoaderService.load();
  }

  private _handleOHLV(historyItem): void {
    if (
      !this.instrument ||
      !compareInstruments(historyItem.instrument, this.instrument)
    )
      return;

    this.lastHistoryItem = historyItem;
    requestAnimationFrame(this._updateOHLVData);
  }

  private _handleQuote(quote: IQuote) {
    if (quote.updateType !== UpdateType.Undefined) return;

    const bestQuote = {
      price: this.getQuoteInfo(quote.price),
      volume: quote.volume,
    };
    if (quote.side === QuoteSide.Ask) {
      this.info.handleBestAsk(bestQuote);
    } else {
      this.info.handleBestBid(bestQuote);
    }
  }

  toggleTrading(): void {
    if (!this.isTradingEnabledGlobally) {
      this.isTradingEnabled = true;
      this._tradeLockService.$isTradingGloballyEnabled.next(true);
      this._changeDetectorRef.detectChanges();
      return;
    }

    this.isTradingEnabled = !this.isTradingEnabled;
    this._changeDetectorRef.detectChanges();
  }

  getQuoteInfo(info: number) {
    return info?.toFixed(this.instrument?.precision ?? 2);
  }

  getQuoteSize(info: number) {
    return info ?? '-';
  }

  saveState(): IChartState {
    const { chart } = this;

    if (!chart) return;

    this.settings.trading.orderArea = this.chartForm.getState() as any;
    if (this.account) {
      this.addPreferredAccount(this.account);
    }

    const preferredAccountsObj: { [key in Id]: Id } = {};
    for (const [connectionId, accountId] of this.preferredAccounts) {
      preferredAccountsObj[connectionId] = accountId;
    }

    return {
      isToolbarVisible: this.toolbar?.isToolbarVisible(),
      showOHLV: this.showOHLV,
      showChanges: this.showChanges,
      showChartForm: this.showChartForm,
      enableOrderForm: this.enableOrderForm,
      link: this.link,
      showCancelConfirm: this.showCancelConfirm,
      instrument: chart.instrument,
      timeFrame: chart.timeFrame,
      periodToLoad: chart.periodToLoad,
      stockChartXState: chart.saveState(),
      intervalOptions: this.toolbar.intervalOptions,
      periodOptions: this.toolbar.periodOptions,
      componentInstanceId: this.componentInstanceId,
      settings: this.settings,
      preferredAccounts: preferredAccountsObj,
    } as IChartState;
  }

  private _instrumentChangeHandler = (event) => {
    this._setUnavaliableIfNeed();
    this.instrument = event.value;
  };

  async loadIndicators(): Promise<void> {
    const indicators: IndicatorModule[] = [
      { name: 'Footprint', path: 'Footprint' },
      { name: 'VWAP', path: 'VWAP' },
      {
        name: 'VolumeBreakdown',
        path: 'VolumeBreakdown',
      },
      { name: 'SessionStats', path: 'SessionStats' },
      {
        name: 'SessionStatsDebug',
        path: 'SessionStatsDebug',
      },
      { name: 'BarStatsDebug', path: 'BarStatsDebug' },
      { name: 'VolumeProfile', path: 'VolumeProfile' },
      { name: 'PriceStats', path: 'PriceStats' },
      {
        name: 'CustomVolumeProfile',
        path: 'CustomVolumeProfile',
      },
      {
        name: 'CompositeProfile',
        path: 'CompositeProfile',
      },
    ];

    const loadPromises: Promise<void>[] = indicators.map(
      async ({ name, path }: IndicatorModule): Promise<void> => {
        const module = await import(`./indicators/graphics/${path}`);
        StockChartX[name] = module[name];
      },
    );

    await Promise.all(loadPromises);
  }

  loadChart() {
    const state = this._loadedState$.value;
    const chart = (this.chart = this._initChart(state));
    this.showChanges = state?.showChanges;
    this.showOHLV = state?.showOHLV;
    if (state?.hasOwnProperty('isToolbarVisible')) {
      this.setToolbarVisibility(state.isToolbarVisible);
    }
    this.enableOrderForm = state?.enableOrderForm;
    this.showChartForm = state?.showChartForm;
    if (state?.hasOwnProperty('settings')) {
      this.settings = state.settings;
    }

    if (state?.hasOwnProperty('showChanges')) {
      this.showChanges = state?.showChanges;
    }
    if (state?.hasOwnProperty('showOHLV')) {
      this.showOHLV = state?.showOHLV;
    }
    if (state?.hasOwnProperty('isToolbarVisible')) {
      this.setToolbarVisibility(state.isToolbarVisible);
    }

    this._setUnavaliableIfNeed();

    if (!chart) {
      return;
    }

    this.broadcastData(this._getCustomVolumeProfileKey(), this);

    chart.shouldDraw = this._shouldDraw;

    this._handleSettingsChange(this.settings);
    this._handleChartScaleSettings(null, this.settings?.valueScale);

    // TRAD-51: setting `this.instrument` is triggering a chart reload which is causing the chart to be reloaded twice
    // this.instrument = state?.instrument ?? environment?.instrument as IStockChartXInstrument;

    // TRAD-51: Set the instrument data without reloading the chart at this point
    this.setInstrument(
      state?.instrument ?? (environment?.instrument as IStockChartXInstrument),
      false,
    );

    this._orderChartMarkers.init();
    this._orderSideMarkers.init();
    this._positionSideMarkers.init();

    this.checkIfTradingEnabled();

    chart.showInstrumentWatermark = false;

    //  this.instrument = this.chart.instrument;

    chart.on(
      StockChartX.ChartEvent.INSTRUMENT_CHANGED + EVENTS_SUFFIX,
      this._instrumentChangeHandler,
    );
    chart.on(StockChartX.ChartEvent.TIME_INTERVAL_CHANGED, () => {
      this._orderChartMarkers.refresh();
    });
    chart.on(
      StockChartX.ChartEvent.SHOW_WAITING_BAR,
      this._handleShowWaitingBar,
    );
    chart.on(
      StockChartX.ChartEvent.HIDE_WAITING_BAR,
      this._handleHideWaitingBar,
    );
    chart.on(StockChartX.PanelEvent.CONTEXT_MENU, this._handleContextMenu);
    chart.on(
      StockChartX.ValueScaleEvents.ContextMenu,
      this._handleValueScaleContextMenu,
    );
    chart.on(
      StockChartX.ChartEvent.INDICATOR_REMOVED,
      this._handleIndicatorRemoved,
    );
    this._themesHandler.themeChange$
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        chart.theme = getScxTheme(this.settings);
        chart.setNeedsUpdate();
      });

    // this.refresh(); // FIX-TRAD-202: Fixes https://tradrr.atlassian.net/browse/TRAD-202

    this._loadedState$.pipe(untilDestroyed(this)).subscribe((state): void => {
      if (!state) {
        this.refresh(); // FIX-TRAD-202: Fixes https://tradrr.atlassian.net/browse/TRAD-202
        return;
      }

      this.checkIfTradingEnabled();

      if (state.instrument && state.instrument.id != null) {
        this.instrument = state.instrument; // todo: test it
      }

      if (
        state.timeFrame != null &&
        this._isTimeFrameAllowed(
          state.timeFrame.periodicity as StockChartXPeriodicityType,
        )
      ) {
        chart.timeFrame = state.timeFrame;
      }

      if (
        state.periodToLoad &&
        !this._isTimeFrameAllowed(
          state.periodToLoad.periodicity as StockChartXPeriodicityType,
        )
      ) {
        chart.periodToLoad = state.periodToLoad;
      }

      if (state.stockChartXState) {
        let stockChartXState: IStockChartXState & {
          timeFrame?: IStockChartXTimeFrame;
          periodToLoad?: IStockChartXTimeFrame;
        } = { ...state.stockChartXState };

        if (
          stockChartXState.timeFrame &&
          !this._isTimeFrameAllowed(
            stockChartXState.timeFrame
              .periodicity as StockChartXPeriodicityType,
          )
        ) {
          stockChartXState.timeFrame = this._getDefaultTimeframe();
        }

        if (
          stockChartXState.periodToLoad &&
          !this._isTimeFrameAllowed(
            stockChartXState.periodToLoad
              .periodicity as StockChartXPeriodicityType,
          )
        ) {
          stockChartXState.periodToLoad = this._getDefaultPeriodToLoad();
        }

        if (
          stockChartXState?.priceStyle?.className &&
          !this._isPriceStyleAllowed(stockChartXState?.priceStyle?.className)
        ) {
          stockChartXState.priceStyle.className = PriceStyleEnum.candle;
        }

        chart.loadState(stockChartXState);
      }
      /* else if (StockChartX.Indicator.registeredIndicators.Vol) {
           chart.addIndicators(new StockChartX.Indicator.registeredIndicators.Vol());
         }*/

      this.enableOrderForm = state?.enableOrderForm;
      this.showChartForm = state?.showChartForm;
      this.checkIfTradingEnabled();
      this._handleSettingsChange(this.settings);
      this._handleChartScaleSettings(null, this.settings?.valueScale);
      this.refresh(false); // FIX-TRAD-235: Fixes https://tradrr.atlassian.net/browse/TRAD-235
    });

    this._loadedChart$.next(chart);

    this.broadcastData(this.chartLink, chart);

    // @todo this can be useful for ad-hoc debugging, find a solution to add it back in a way that it doesn't mess up with the GC (maybe use WeakRefs)
    // if (environment.isDev) {
    //   let charts = [];
    //   if (!(window as any).charts) {
    //     (window as any).charts = [];
    //   }
    //
    //   charts = (window as any).charts;
    //   charts.push(chart);
    // }
  }

  getFormDTO() {
    return this._sideForm.getDto();
  }

  private _handleShowWaitingBar = (e) => {
    this.loading = true;
    this._changeDetectorRef.detectChanges();
  };

  private _isTimeFrameAllowed(timeFrame: StockChartXPeriodicityType): boolean {
    const settingsData: SettingsData =
      this._settingsService.settings.getValue();

    if (
      !(<StockChartXPeriodicityType[]>[
        StockChartXPeriodicityEnum.RANGE,
        StockChartXPeriodicityEnum.RENKO,
        StockChartXPeriodicityEnum.VOLUME,
        StockChartXPeriodicityEnum.TICK,
      ]).includes(timeFrame)
    ) {
      return true;
    }

    switch (true) {
      case timeFrame === StockChartXPeriodicityEnum.RANGE &&
        settingsData?.admin?.betaFeatures?.chartIntervals?.range:
        return true;
      case timeFrame === StockChartXPeriodicityEnum.RENKO &&
        settingsData?.admin?.betaFeatures?.chartIntervals?.renko:
        return true;
      case timeFrame === StockChartXPeriodicityEnum.VOLUME &&
        settingsData?.admin?.betaFeatures?.chartIntervals?.volume:
        return true;
      case timeFrame === StockChartXPeriodicityEnum.TICK &&
        settingsData?.admin?.betaFeatures?.chartIntervals?.ticks:
        return true;
    }

    return false;
  }

  private _isPriceStyleAllowed(priceStyle: PriceStyleEnum): boolean {
    const settingsData: SettingsData =
      this._settingsService.settings.getValue();

    switch (true) {
      case priceStyle === PriceStyleEnum.kagi &&
        settingsData?.admin?.betaFeatures?.chartBarStyles?.kagi:
        return true;
      case priceStyle === PriceStyleEnum.lineBreak &&
        settingsData?.admin?.betaFeatures?.chartBarStyles?.lineBreak:
        return true;
      case priceStyle === PriceStyleEnum.pointAndFigure &&
        settingsData?.admin?.betaFeatures?.chartBarStyles?.pointAndFigure:
        return true;
      case priceStyle === PriceStyleEnum.renko &&
        settingsData?.admin?.betaFeatures?.chartBarStyles?.renko:
        return true;
    }

    return false;
  }

  private _isPeriodToLoadAllowed(
    periodToLoad: StockChartXPeriodicityType,
  ): boolean {
    const settingsData: SettingsData =
      this._settingsService.settings.getValue();

    switch (true) {
      case periodToLoad === StockChartXPeriodicityEnum.YEAR &&
        settingsData?.admin?.betaFeatures?.chartPeriodsToLoad?.years:
        return true;
    }

    return false;
  }

  private _handleHideWaitingBar = (e) => {
    this.loading = false;
    this._changeDetectorRef.detectChanges();
  };
  private _handleIndicatorRemoved = () => {
    setTimeout(() => {
      this.chart.setNeedsLayout();
      this.chart.setNeedsUpdate(true);
    });
  };

  private _handleValueScaleContextMenu = (e) => {
    this.contextEvent = e.target.evt.originalEvent;
    this.openSettingsDialog(SettingsItems.ValueScale, { x: -24, y: 0 });
    this._selectValueScale();
  };

  private _handleContextMenu = (e) => {
    this.activeIndicator = this.chart.indicators.find((i) => i.isActive);

    const event = e.value.event.evt.originalEvent;
    this.contextEvent = event;

    this.nzContextMenuService.create(event, this.menu);
  };

  private _setUnavaliableIfNeed() {
    if (!this.chart) {
      return;
    }

    this.isChartUnavailable =
      this.chart.instrument && this.chart.instrument.id === null;
  }

  setNeedUpdate() {
    if (this.chart) {
      this.chart.setNeedsUpdate();
    }
  }

  protected _initChart(state?: IScxComponentState): any {
    StockChartX.Environment.Path.view = './assets/StockChartX/view/';
    StockChartX.Environment.Path.locales = './assets/StockChartX/locales/';

    const { chartContainer } = this;
    let periodToLoad: IStockChartXTimeFrame = this._getDefaultPeriodToLoad();
    let timeFrame: IStockChartXTimeFrame = this._getDefaultTimeframe();

    if (
      state?.timeFrame &&
      this._isTimeFrameAllowed(
        state?.timeFrame?.periodicity as StockChartXPeriodicityType,
      )
    ) {
      timeFrame = state.timeFrame;
    }

    if (
      state?.periodToLoad &&
      this._isPeriodToLoadAllowed(
        state?.periodToLoad?.periodicity as StockChartXPeriodicityType,
      )
    ) {
      periodToLoad = state.periodToLoad;
    }

    if (!chartContainer || !chartContainer.nativeElement) {
      return null;
    }

    return new StockChartX.Chart({
      container: $(chartContainer.nativeElement),
      keyboardEventsEnabled: false, // todo: handle key shortcut
      showToolbar: false,
      showScrollbar: false,
      allowReadMoreHistory: true,
      autoSave: false,
      useWaitingBar: false,
      autoLoad: false,
      showInstrumentWatermark: false,
      incomePrecision: state?.instrument?.precision ?? 2,
      stayInDrawingMode: false,
      datafeed: this.datafeed,
      timeFrame,
      periodToLoad,
      theme: getScxTheme(),
    } as IChartConfig);
  }

  private _getDefaultTimeframe(): IStockChartXTimeFrame {
    return {
      interval: 1,
      periodicity: StockChartXPeriodicityEnum.HOUR,
    };
  }

  private _getDefaultPeriodToLoad(): IStockChartXTimeFrame {
    return {
      interval: 3,
      periodicity: StockChartXPeriodicityEnum.DAY,
    };
  }

  update(data) {
    const { chart } = this;

    if (!chart || !data) {
      return;
    }

    const { instrument, account } = data;

    if (instrument) {
      this.instrument = instrument;
    }
    if (account) {
      this.account = account;
      this._requestTradeRoutes();
    }

    chart.sendBarsRequest();
  }

  refresh(chartReload = true): void {
    const { chart } = this;

    if (!chart || !this.instrument || !this._account) {
      return;
    }

    if (chartReload && chart.reload) {
      chart.reload();
    }

    this._unprocessedOrderItems = [];
    this._processedOrderItems = [];
    this._positionSideMarkers.refresh();
    this._orderSideMarkers.refresh();
    this._orderChartMarkers.refresh();
    this._requestTradeRoutes();
  }

  handleNodeEvent(name: LayoutNodeEvent, data) {
    switch (name) {
      case LayoutNodeEvent.Close:
      case LayoutNodeEvent.Destroy:
      case LayoutNodeEvent.Hide:
        this._closeSettings();
        this.onWindowClose();
        break;
      case LayoutNodeEvent.Resize:
      case LayoutNodeEvent.Maximize:
      case LayoutNodeEvent.Restore:
      case LayoutNodeEvent.MakeVisible:
        this.chart?.handleResize();
        this.setNeedUpdate();
        this.toolbar?.updateOffset();
        break;
      case LayoutNodeEvent.Move:
        this.toolbar.updateOffset();
        break;
      case LayoutNodeEvent.Event:
        return this._handleKey(data);
    }
  }

  private _handleKey(event) {
    if (!(event instanceof KeyboardEvent)) {
      return false;
    }
    this.keysStack.handle(event);
    this.toolbar?.items.forEach((item) => {
      const hotkey = item.settings.general?.drawVPC;
      if (hotkey) {
        const keyBinding = KeyBinding.fromDTO(hotkey);
        if (this.keysStack.equals(keyBinding))
          this.createCustomVolumeProfile(item);
      }
    });
  }

  handleLinkData(data: any) {
    this.update(data);
  }

  private _getInstrumentCompany() {
    return '';
  }

  private _getNavbarTitle(): string {
    if (this.instrument) {
      const timeFrame = this.chart.timeFrame;
      let name = this.instrument.symbol;
      if (this.instrument.description) {
        name += ` - ${this.instrument.description}`;
      }
      name += `, ${timeFrame.interval}${transformPeriodicity(timeFrame.periodicity)}`;

      return name;
    }
  }

  loadState(state1?: IChartState): void {
    let state = clone(state1);
    this.settings = state?.settings || clone(defaultChartSettings);
    this.link = state?.link ?? Math.random();
    this._loadedState$.next(state);
    if (state?.account) {
      this.account = state.account;
      this._requestTradeRoutes();
    }
    if (state?.preferredAccounts) {
      this.preferredAccounts = new Map(Object.entries(state.preferredAccounts));
    }
    if (state?.componentInstanceId) {
      this.componentInstanceId = state.componentInstanceId;
    }
    if (state?.intervalOptions) {
      this.intervalOptions = state.intervalOptions;
    }
    if (state?.periodOptions) {
      this.periodOptions = state.periodOptions;
    }

    this._handleChartScaleSettings(null, this.settings?.valueScale);

    this.addLinkObserver({
      link: this._getSettingsKey(),
      layoutContainer: this.layoutContainer,
      handleLinkData: this._handleSettingsChange.bind(this),
    });
    this.addLinkObserver({
      link: this._getCustomVolumeProfileKey(),
      layoutContainer: this.layoutContainer,
      handleLinkData: this._handleCustomVolumeProfileSettingsChange.bind(this),
    });
  }

  loadTemplate(template: IChartTemplate): void {
    this.loadedTemplate = template;
    this.loadState(template.state);
  }

  selectCustomeVolumeTemplate(template: IVolumeTemplate) {
    if (this.activeIndicator?.templateId == template?.id) return;

    this.activeIndicator.templateId = template.id;
    this.loadedCustomeVolumeTemplate = template;
    this.activeIndicator.settings = template.settings;
    //  this.chart?.setNeedsUpdate();
  }

  loadCustomeVolumeTemplate(template: IVolumeTemplate): void {
    this.loadedCustomeVolumeTemplate = template;

    this.createCustomVolumeProfile(template);
  }

  ngOnDestroy(): void {
    const subscriberId: string = this.constructor['ɵcmp'].id;

    this.destroy();
    this._rProtocolOrderPlantService.unsubscribeFromTradeRoutes(subscriberId);
    if (this.orderNotificationsSubscription) {
      this.orderNotificationsSubscription.unsubscribe();
    }
  }

  destroy() {
    this._positionSideMarkers.destroy();
    this._orderSideMarkers.destroy();
    this._orderChartMarkers.destroy();

    this._templatesSubscription?.unsubscribe();

    if (this.chart) {
      this.chart.off(
        StockChartX.ChartEvent.INSTRUMENT_CHANGED + EVENTS_SUFFIX,
        this._instrumentChangeHandler,
      );
      this.chart.off(
        StockChartX.PanelEvent.CONTEXT_MENU,
        this._handleContextMenu,
      );
      this.chart.off(
        StockChartX.ChartEvent.SHOW_WAITING_BAR,
        this._handleShowWaitingBar,
      );
      this.chart.off(
        StockChartX.ChartEvent.HIDE_WAITING_BAR,
        this._handleHideWaitingBar,
      );
      this.chart.off(
        StockChartX.ChartEvent.INDICATOR_REMOVED,
        this._handleIndicatorRemoved,
      );
      this.chart.off(
        StockChartX.ValueScaleEvents.ContextMenu,
        this._handleValueScaleContextMenu,
      );
      this.chart.destroy();
    }

    this.unsubscribe();
    this.chart = null;
  }

  onWindowClose() {
    const link = this.chartLink;
    this.layout.removeComponents((item) => {
      const isIndicatorComponent = [
        Components.Indicators,
        Components.IndicatorList,
      ].includes(item.type);
      return (
        item.visible &&
        isIndicatorComponent &&
        item.options.componentState()?.state?.link === link
      );
    });
  }

  handleFormAction($event: FormActionData) {
    switch ($event.action) {
      case FormActions.CreateOcoOrder:
        if (this.ocoStep === OcoStep.None) this.ocoStep = OcoStep.First;
        break;
      case FormActions.CancelOcoOrder:
        this.clearOcoOrders();
        break;
      case FormActions.CloseOrders:
        this._closeOrders();
        break;
      case FormActions.CloseBuyOrders:
        this._closeOrders(OrderSide.Buy);
        break;
      case FormActions.CloseSellOrders:
        this._closeOrders(OrderSide.Sell);
        break;
      case FormActions.CreateBuyMarketOrder:
        this.createOrder({ side: OrderSide.Buy, type: OrderType.Market });
        break;
      case FormActions.CreateSellMarketOrder:
        this.createOrder({ side: OrderSide.Sell, type: OrderType.Market });
        break;
      case FormActions.Flatten:
        this._closePositions();
        this._closeOrders();
        break;
      case FormActions.ClosePositions:
        this._closePositions();
        break;
    }
  }

  clearOcoOrders() {
    this.ocoStep = OcoStep.None;
    this.secondOcoOrder = null;
    this.firstOcoOrder = null;
    this._orderSideMarkers.clearOcoOrders();
  }

  createOrderWithConfirm(config: Partial<IOrder>, event): void {
    if (!this.isTradingEnabledGlobally || !this.isTradingEnabled) {
      return;
    }

    if (this.showOrderConfirm) {
      const dto = this._sideForm.getDto();
      const priceSpecs = getPriceSpecs(
        dto,
        config.price,
        this.instrument.tickSize,
      );
      this.createConfirmModal(
        {
          order: { ...dto, ...config, ...priceSpecs },
          instrument: this.instrument,
        },
        event,
      )
        .afterClose.pipe(untilDestroyed(this))
        .subscribe((res) => {
          if (res) {
            if (res.create) this.createOrder(config);
            this.showOrderConfirm = !res.dontShowAgain;
          }
        });
    } else {
      this.createOrder(config);
    }
  }

  async cancelOrderWithConfirm(order: IOrder, event) {
    if (!this.showCancelConfirm)
      return Promise.resolve({
        create: true,
        dontShowAgain: !this.showCancelConfirm,
      });

    return this.createConfirmModal(
      {
        order,
        instrument: this.instrument,
        prefix: 'Cancel',
      },
      event,
    ).afterClose.toPromise();
  }

  private createConfirmModal(
    params,
    event,
  ): NzModalRef<ConfirmOrderComponent, any> {
    const { price, type, side } = params.order;
    const ordersGroup: IOrder[] = this._chartService.getOrdersGroup(
      this._orderSideMarkers.getOrders(),
      price,
      type,
      side,
    );

    return this._modalService.create({
      nzClassName: 'confirm-order',
      nzIconType: null,
      nzContent: ConfirmOrderComponent,
      nzFooter: null,
      nzNoAnimation: true,
      nzStyle: {
        left: `${event.evt.screenX - confirmModalWidth / 2}px`,
        top: `${event.evt.clientY - confirmModalHeight / 2}px`,
      },
      nzComponentParams: {
        ...params,
        displayedQuantity:
          this._chartService.getOrdersGroupQuantity(ordersGroup),
      },
    });
  }

  createOrder(config: Partial<IOrder>): void {
    if (!this.isTradingEnabledGlobally || !this.isTradingEnabled) {
      this._notifier.showError("You can't create order when trading is locked");
      return;
    }

    const isOCO = this.ocoStep !== OcoStep.None;
    const dto = { ...this.getFormDTO(), ...config };

    if (this.position) {
      const intent: TradeIntent = new TradeIntent(
        this.position,
        config.side,
        dto,
      );
      if (intent.isExitingPosition || intent.isScaleOutPosition) {
        delete dto.stopLoss;
        delete dto.takeProfit;
      }
    }

    const priceSpecs = getPriceSpecs(
      dto,
      config.price,
      this.instrument.tickSize,
    );
    const order = {
      ...dto,
      ...priceSpecs,
      accountId: this.accountId,
    };
    if (isOCO) {
      order.isOco = true;
      if (!this.firstOcoOrder) {
        this.firstOcoOrder = order;
        this._orderSideMarkers.createOcoOrder(order);
        this.ocoStep =
          this.ocoStep === OcoStep.None ? OcoStep.First : OcoStep.Second;
      } else if (!this.secondOcoOrder) {
        this.secondOcoOrder = order;
        this.ocoStep =
          this.ocoStep === OcoStep.None ? OcoStep.First : OcoStep.Second;
      }
      if (this.firstOcoOrder && this.secondOcoOrder) {
        this.secondOcoOrder.triggerPrice = this.secondOcoOrder.price;
        this._createOcoOrder([this.firstOcoOrder, this.secondOcoOrder]);
        this.clearOcoOrders();
      }
      return;
    }

    if (order.stopLoss?.stopLoss || order.takeProfit?.takeProfit) {
      this._createBracketOrder(order);
    } else {
      this._createOrder(order);
    }
  }

  openOrderPanel() {
    return this.layout.addComponent({
      component: {
        name: 'ordersPanel',
        state: {
          orders: this._orderSideMarkers.getOrders(),
        },
      },
      single: true,
      height: 400,
      width: 750,
      resizable: true,
      removeIfExists: true,
    });
  }

  private _createOrder(order: Omit<IOrder, 'id'>): void {
    const orderPlantWebSocket: RProtocolConnectionWebSocketService =
      this._getOrderPlantWebSocket();

    if (!this.isTradingEnabled) {
      this._notifier.showError('Trading is disabled for this view.');
      return;
    }

    if (order.type === OrderType.StopMarket) {
      order.triggerPrice = order.price;
    }

    order.account = this.account as unknown as OrderAccount;

    orderPlantWebSocket.requestNewOrder(
      order as IOrder,
      this.isTradingEnabledGlobally,
    );
  }

  private _createBracketOrder(order: IOrder): void {
    const orderPlantWebSocket: RProtocolConnectionWebSocketService = <
      RProtocolConnectionWebSocketService
    >this._webSocketRegistryService.getRProtocolOrderPlant(
      this._accountsManager.getConnectionByAccountId(this.account.id),
    );

    order.account = <OrderAccount>(<unknown>this.account);

    this._rProtocolOrderPlantService
      .requestBracketOrder(
        order,
        orderPlantWebSocket,
        this._tradeLockService.$isTradingGloballyEnabled.getValue(),
      )
      .subscribe(
        (): void => {},
        (): void => {
          this._notifier.showError('Failed to create bracket order.');
        },
      );
  }

  private _createOcoOrder(orders: IOrder[]): void {
    const orderPlantWebSocket: RProtocolConnectionWebSocketService =
      this._getOrderPlantWebSocket();

    orders[0].account = <OrderAccount>(<unknown>this.account);
    orders[1].account = <OrderAccount>(<unknown>this.account);

    orderPlantWebSocket.requestOcoOrder(
      orders,
      this._tradeLockService.$isTradingGloballyEnabled.getValue(),
    );
  }

  private _closeOrders(side?: OrderSide): void {
    const orders: any = this._orderSideMarkers.getOrders(side);
    const orderPlantWebSocket: RProtocolConnectionWebSocketService =
      this._getOrderPlantWebSocket();

    orders.forEach((order: IOrder): void => {
      this._rProtocolOrderPlantService
        .requestCancelOrder(
          order,
          orderPlantWebSocket,
          this._tradeLockService.$isTradingGloballyEnabled.getValue(),
        )
        .subscribe(
          (): void => {},
          (): void => {
            this._notificationService.showError(
              `Error while closing order: ${order.id}`,
            );
          },
        );
    });
  }

  private _closePositions(): void {
    const account: OrderAccount = this._accountsManager.getAccountById(
      this.accountId,
    ) as any as OrderAccount;

    this._rProtocolOrderPlantService
      .requestExitPosition(
        this.instrument,
        account,
        this._getOrderPlantWebSocket(),
      )
      .pipe(take(1))
      .subscribe(
        (): void => {},
        (): void => {
          this._notificationService.showError(`Error while closing position.`);
        },
      );
  }

  checkIfTradingEnabled() {
    if (this.chart.mainPanel.tradingPanel)
      this.chart.mainPanel.tradingPanel.visible = this.enableOrderForm === true;

    this.chart.mainPanel.orders.forEach(
      (item) => (item.visible = this.enableOrderForm),
    );
    this.chart.setNeedsUpdate(true);
  }

  saveTemplate(
    template?: Pick<IChartTemplate, 'id' | 'name' | 'isDefault'>,
  ): void {
    if (!this.loadedTemplate && !template) {
      return;
    }

    const templateData: IChartTemplate = {
      state: this.saveState(),
      tabState: this.getTabState(),
      id: this.loadedTemplate?.id,
      name: this.loadedTemplate?.name,
      type: Components.Chart,
      isDefault: false,
      ...template,
    };
    if (templateData.state?.preferredAccounts && template.isDefault) {
      // Remove preferred accounts from default template (TRAD-328)
      delete templateData.state.preferredAccounts;
    }

    this._templatesService.updateItem(templateData).subscribe(
      (result: IChartTemplate): void => {
        this.loadedTemplate = result;
      },
      (error): void =>
        this._notifier.showError(
          error,
          `Failed to save template: ${templateData.name}`,
        ),
    );
  }

  saveAsDefault(): void {
    const defaultTemplateData: Pick<IChartTemplate, 'name' | 'isDefault'> = {
      name: this._templatesService.DEFAULT_TEMPLATE_NAME,
      isDefault: true,
    };

    if (!this.chartTemplates) {
      return;
    }

    if (this._templatesService.hasDefaultTemplate(this.chartTemplates)) {
      this.saveTemplate({
        id: this._templatesService.getDefaultTemplate(this.chartTemplates).id,
        ...defaultTemplateData,
      });
    } else {
      this._createTemplate(defaultTemplateData);
    }
  }

  openNewTemplatePopup(): void {
    const modal: NzModalRef = this._modalService.create({
      nzWidth: 440,
      nzTitle: 'Save as',
      nzContent: CreateModalComponent,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        name: 'Template name',
      },
    });

    modal.afterClose.subscribe(({ name }): void => {
      if (!name) {
        return;
      }

      this._createTemplate({ name });
    });
  }

  private _createTemplate(template: Partial<IChartTemplate>): void {
    this._templatesService
      .createItem({
        state: this.saveState(),
        tabState: this.getTabState(),
        name: template.name,
        type: Components.Chart,
        isDefault: false,
        ...template,
      })
      .subscribe(
        (result: IChartTemplate): void => {
          this.loadedTemplate = result;
        },
        (error): void =>
          this._notifier.showError(
            error,
            `Failed to create template: ${template.name}`,
          ),
      );
  }

  private async _handleCustomVolumeProfileSettingsChange(data: {
    template: IVolumeTemplate;
    identificator: any;
  }) {
    if (data == null || data.template == null) return;

    const isValid =
      await this._volumeProfileTemplatesRepository.validateHotkeyTemplate(
        data.template,
      );

    if (!isValid) {
      data.template.settings.general.drawVPC = null;
      this._notifier.showError("You can't set hotkey that is already taken");
      this.broadcastData(this._getCustomVolumeProfileKey(), data);
      return;
    }

    if (data?.template?.id) {
      const indicators = this.chart.indicators.filter(
        (i) =>
          i instanceof StockChartX.CustomVolumeProfile &&
          i.templateId == data.template.id,
      );
      for (const indicator of indicators) {
        indicator.settings = data.template.settings;
      }
    } else {
      const indicator = this.chart.indicators.find(
        (i) => i === data.identificator,
      );

      if (indicator) {
        indicator.settings = data.template.settings;
      }
    }
  }

  private _handleSettingsChange(settings: IChartSettings): void {
    const session = settings.session?.sessionTemplate;

    if (
      this.session?.id != session?.id ||
      this.settings.session.sessionEnabled != settings.session.sessionEnabled
    ) {
      this.datafeed.setSession(
        settings.session.sessionEnabled ? session : null,
        this.chart,
      );
    }
    this.settings = settings;

    const { trading } = settings;
    const ordersEnabled = trading.trading.showWorkingOrders;
    this._orderSideMarkers.setVisibility(ordersEnabled);
    this.showOHLV = trading.trading.showOHLVInfo;
    this.showChanges = trading.trading.showInstrumentChange;
    const oldTheme = this.chart.theme;
    const theme = getScxTheme(settings);
    this._sideForm.loadState({
      settings: mapSettingsToSideFormState(settings),
    });

    this.chart.theme = theme;

    if (
      oldTheme.tradingPanel.tradingBarLength !=
        theme.tradingPanel.tradingBarLength ||
      theme.tradingPanel.tradingBarUnit != oldTheme.tradingPanel.tradingBarUnit
    ) {
      this.chart.handleResize();
    }

    this.chart.setNeedsUpdate();
  }

  private _handleChartScaleSettings(
    oldValueScale: IChartSettings['valueScale'],
    newValueScale: IChartSettings['valueScale'],
  ): void {
    const newScaleMode: IsAutomaticPixelPrice = newValueScale?.valueScale
      ?.isAutomatic as IsAutomaticPixelPrice;
    const oldScaleMode: IsAutomaticPixelPrice = oldValueScale?.valueScale
      ?.isAutomatic as IsAutomaticPixelPrice;
    const newPixelPrice: number = newValueScale?.valueScale?.pixelsPrice ?? 10;
    const oldPixelPrice: number = oldValueScale?.valueScale?.pixelsPrice;
    const oldFirstVisibleRecord: number = this.chart?.firstVisibleRecord;
    const oldLastVisibleRecord: number = this.chart?.lastVisibleRecord;

    if (!this.chart) {
      return;
    }

    if (!newValueScale && oldValueScale) {
      this.chart.setValueScaleType(oldScaleMode);
      this.chart.setPixelsPrice(oldPixelPrice);
      this.chart.setNeedsUpdate();
      return;
    }

    switch (true) {
      case oldValueScale === newValueScale:
        break;

      case oldScaleMode === IsAutomaticPixelPrice.AUTOMATIC &&
        newScaleMode === IsAutomaticPixelPrice.PIXELS_PRICE:
        this.chart.setValueScaleType(IsAutomaticPixelPrice.PIXELS_PRICE);
        this.chart.setPixelsPrice(newPixelPrice);
        this.chart.setNeedsUpdate();
        break;

      case oldScaleMode === IsAutomaticPixelPrice.PIXELS_PRICE &&
        newScaleMode === IsAutomaticPixelPrice.AUTOMATIC:
      case !oldValueScale && newScaleMode === IsAutomaticPixelPrice.AUTOMATIC:
        this.chart.setValueScaleType(IsAutomaticPixelPrice.AUTOMATIC);
        this.chart.setPixelsPrice(0);
        this.chart.setNeedsUpdate(true);
        this.chart.update();
        break;

      case oldScaleMode === IsAutomaticPixelPrice.PIXELS_PRICE &&
        newScaleMode === IsAutomaticPixelPrice.PIXELS_PRICE &&
        oldPixelPrice !== newPixelPrice:
      case !oldValueScale &&
        newScaleMode === IsAutomaticPixelPrice.PIXELS_PRICE:
        this.chart.setValueScaleType(IsAutomaticPixelPrice.PIXELS_PRICE);
        this.chart.setPixelsPrice(newPixelPrice);
        this.chart.setNeedsUpdate();
        break;
    }

    if (oldFirstVisibleRecord && oldLastVisibleRecord) {
      this.chart.recordRange(oldFirstVisibleRecord, oldLastVisibleRecord);
    }
  }

  private _getSettingsKey() {
    return `${this.componentInstanceId}.${chartSettings}`;
  }

  private _getCustomVolumeProfileKey() {
    return `${this._getSettingsKey()}.cvp`;
  }

  openSettingsDialog(menuItem?: SettingsItems, offset = { x: 0, y: 0 }): void {
    const linkKey = this._getSettingsKey();
    const widget = this.layout.findComponent((item: IWindow) => {
      return (
        item.type === Components.ChartSettings &&
        (item.component as any).linkKey === linkKey
      );
    });

    if (widget) widget.focus();
    else {
      const coords: any = {};
      if (this.contextEvent) {
        coords.x = this.contextEvent.clientX + offset.x;
        coords.y = this.contextEvent.clientY + offset.y;
      }
      this.layout.addComponent({
        component: {
          name: chartSettings,
          state: {
            linkKey,
            settings: this.settings,
            menuItem,
          },
        },
        closeBtn: true,
        single: false,
        width: 618,
        height: 475,
        ...coords,
        allowPopup: false,
        closableIfPopup: true,
        resizable: false,
        removeIfExists: false,
        minimizable: false,
        maximizable: false,
      });
    }
  }

  private _selectValueScale(): void {
    const linkKey = this._getSettingsKey();
    this.broadcastData(chartReceiveKey + linkKey, {
      type: SettingsItems.ValueScale,
    });
  }

  openVolumeSettingsDialog() {
    if (!this.activeIndicator) return;

    const linkKey = this._getCustomVolumeProfileKey();
    const widget = this.layout.findComponent((item: IWindow) => {
      return (
        item.type === Components.ChartVolumeSettings &&
        (item.component as any).linkKey === linkKey
      );
    });
    if (widget) {
      widget.focus();
    } else {
      const coords: any = {};
      if (this.contextEvent) {
        coords.x = this.contextEvent.clientX;
        coords.y = this.contextEvent.clientY;
      }
      const template = this._volumeProfileTemplatesRepository
        .getTemplates()
        .find((item) => item.id == this.activeIndicator.templateId);
      this.layout.addComponent({
        component: {
          name: customVolumeProfileSettings,
          state: {
            linkKey,
            identificator: this.activeIndicator,
            template,
            chart: this.chart,
          },
        },
        closeBtn: true,
        single: false,
        width: 618,
        height: 475,
        ...coords,
        allowPopup: false,
        closableIfPopup: true,
        resizable: false,
        removeIfExists: false,
        minimizable: false,
        maximizable: false,
      });
    }
  }

  private _closeSettings() {
    this.layout.removeComponents((item) => {
      const isSettingsComponent = Components.ChartSettings === item.type;
      return (
        item.visible &&
        isSettingsComponent &&
        item.options.componentState()?.state?.linkKey === this._getSettingsKey()
      );
    });
  }

  updateInSettings(): void {
    this.settings.trading.trading.showOHLVInfo = this.showOHLV;
    this.settings.trading.trading.showInstrumentChange = this.showChanges;

    // todo: update order chart markers settings

    this.broadcastData(chartReceiveKey + this._getSettingsKey(), this.settings);
  }

  saveCustomVolume(): void {
    if (!this.loadedCustomeVolumeTemplate) return;

    const template: IVolumeTemplate = {
      id: this.loadedCustomeVolumeTemplate.id,
      name: this.loadedCustomeVolumeTemplate.name,
      settings: this.activeIndicator?.settings,
    };

    this._volumeProfileTemplatesRepository
      .updateItem(template)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loadedCustomeVolumeTemplate = template;
        },
        (error) => this._notifier.showError(error, 'Failed to save Template'),
      );
  }

  saveAsCustomVolume(): void {
    const modal = this._modalService.create({
      nzTitle: 'Save as',
      nzContent: RenameModalComponent,
      nzClassName: 'modal-dialog-workspace',
      nzWidth: 438,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        label: 'Template name',
      },
    });

    modal.afterClose.subscribe((result) => {
      if (!result) return;

      const template: IVolumeTemplate = {
        id: Date.now().toString(),
        name: result,
        settings: this.activeIndicator?.settings,
      };
      this._volumeProfileTemplatesRepository.createItem(template).subscribe(
        (template) => {
          this.loadedCustomeVolumeTemplate = template;
        },
        (error) => this._notifier.showError(error, 'Failed to create Template'),
      );
    });
  }

  editCustomProfile(template: IVolumeTemplate): void {
    const modal = this._modalService.create({
      nzTitle: 'Edit name',
      nzContent: RenameModalComponent,
      nzClassName: 'modal-dialog-workspace',
      nzWidth: 438,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        label: 'Template name',
      },
    });

    modal.afterClose.subscribe((name) => {
      if (!name) return;

      this._volumeProfileTemplatesRepository
        .updateItem({ ...template, name })
        .subscribe();
    });
  }

  deleteVolumeProfile(template: IVolumeTemplate): void {
    const modal = this._modalService.create({
      nzContent: ConfirmModalComponent,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        message: 'Do you want delete the template?',
        confirmText: 'Delete',
        cancelText: 'Cancel',
      },
    });

    modal.afterClose.subscribe((result) => {
      if (result && result.confirmed) {
        this._volumeProfileTemplatesRepository
          .deleteItem(+template.id)
          .subscribe();
      }
    });
  }

  private _loadTemplateList(): void {
    // this._volumeProfileTemplatesRepository.subscribe((data) => {
    //   this.customeVolumeTemplate = data?.items || [];
    // });
  }

  private _subscribeToHotKey(): void {}

  private _getOrderPlantWebSocket(): RProtocolConnectionWebSocketService {
    const connection: IConnection = this.account?.id
      ? this._accountsManager.getConnectionByAccountId(this.account.id)
      : this._accountsManager.getFirstActiveConnection();

    return this._webSocketRegistryService.getRProtocolOrderPlant(
      connection,
    ) as RProtocolConnectionWebSocketService;
  }

  private _handleIntervalAvailability(
    intervalPeriod: IntervalPeriodEnum,
    isIntervalEnabled: boolean,
  ): void {
    const intervalOption: IIntervalOption = this.intervalOptions.find(
      (intervalOption: IIntervalOption): boolean =>
        intervalOption.period === intervalPeriod,
    );

    if (intervalOption && !isIntervalEnabled) {
      this.intervalOptions = this.intervalOptions.filter(
        (intervalOption: IIntervalOption): boolean =>
          intervalOption.period !== intervalPeriod,
      );
    } else if (!intervalOption && isIntervalEnabled) {
      this.intervalOptions = this._getSortedIntervalOptions([
        ...this.intervalOptions,
        this._TOGGLABLE_INTERVAL_OPTIONS[intervalPeriod],
      ]);
    }

    if (
      this.chart?.timeFrame?.periodicity ===
        IntervalPeriodToStockChartXPeriodicityEnum[intervalPeriod] &&
      !isIntervalEnabled
    ) {
      this.chart.timeFrame = this._getDefaultTimeframe();
      this.chart.reload();
    }
  }

  private _handlePeriodToLoadAvailability(
    periodToLoad: PeriodToLoadEnum,
    isPeriodToLoadEnabled: boolean,
  ): void {
    const periodToLoadOption: IPeriodToLoadOption = this.periodOptions.find(
      (intervalOption: IPeriodToLoadOption): boolean =>
        intervalOption.period === periodToLoad,
    );

    if (periodToLoadOption && !isPeriodToLoadEnabled) {
      this.periodOptions = this.periodOptions.filter(
        (intervalOption: IPeriodToLoadOption): boolean =>
          intervalOption.period !== periodToLoad,
      );
    } else if (!periodToLoadOption && isPeriodToLoadEnabled) {
      this.periodOptions = this._getSortedPeriodToLoadOptions([
        ...this.periodOptions,
        this._TOGGLABLE_PERIOD_TO_LOAD_OPTIONS[periodToLoad],
      ]);
    }

    if (
      this.chart?.periodToLoad?.periodicity ===
        PeriodsToLoadToStockChartXPeriodicityEnum[periodToLoad] &&
      !isPeriodToLoadEnabled
    ) {
      this.chart.periodToLoad = this._getDefaultPeriodToLoad();
      this.chart.reload();
    }
  }

  private _getSortedIntervalOptions(
    unsortedIntervalOptions: IIntervalOption[],
  ): IIntervalOption[] {
    let sortedIntervalOptions: IIntervalOption[] = new Array(
      this._INTERVAL_PERIODS_ORDER.size,
    ).fill(null);

    unsortedIntervalOptions.forEach((intervalOption: IIntervalOption): void => {
      const index: number = this._INTERVAL_PERIODS_ORDER.get(
        intervalOption.period,
      );

      sortedIntervalOptions[index] = intervalOption;
    });

    sortedIntervalOptions = sortedIntervalOptions.filter(
      (sortedIntervalOptions) => sortedIntervalOptions !== null,
    );

    return sortedIntervalOptions;
  }

  private _getSortedPeriodToLoadOptions(
    unsortedPeriodToLoadOptions: IPeriodToLoadOption[],
  ): IPeriodToLoadOption[] {
    let sortedPeriodToLoadOptions: IPeriodToLoadOption[] = new Array(
      this._PERIODS_TO_LOAD_ORDER.size,
    ).fill(null);

    unsortedPeriodToLoadOptions.forEach(
      (periodToLoadOption: IPeriodToLoadOption): void => {
        const index: number = this._PERIODS_TO_LOAD_ORDER.get(
          periodToLoadOption.period,
        );

        sortedPeriodToLoadOptions[index] = periodToLoadOption;
      },
    );

    sortedPeriodToLoadOptions = sortedPeriodToLoadOptions.filter(
      (sortedIntervalOptions) => sortedIntervalOptions !== null,
    );

    return sortedPeriodToLoadOptions;
  }
}

function setCandleBackground(barTheme, settings: IChartSettings) {
  if (barTheme.upCandle.fill) {
    barTheme.upCandle.fill.fillColor = settings.general.upCandleColor;
  } else {
    barTheme.upCandle.border.strokeColor = settings.general.upCandleColor;
  }
  if (barTheme.downCandle.fill) {
    barTheme.downCandle.fill.fillColor = settings.general.downCandleColor;
  } else {
    barTheme.downCandle.border.strokeColor = settings.general.downCandleColor;
  }
}

function setBarBackground(barTheme, settings) {
  barTheme.upBar.strokeColor = settings.general.upCandleColor;
  barTheme.downBar.strokeColor = settings.general.downCandleColor;
}

function setWickColor(barTheme, settings: IChartSettings) {
  barTheme.upCandle.wick.strokeColor = settings.general.wickColor;
  barTheme.downCandle.wick.strokeColor = settings.general.wickColor;
}

function getScxTheme(settings: IChartSettings = defaultChartSettings) {
  const theme = clone(StockChartX.Theme.Dark);

  theme.plot.line.simple.strokeColor = settings.general.lineColor;
  theme.plot.bar.OHLC.strokeColor = settings.general.lineColor;
  theme.plot.line.mountain.line.strokeColor = settings.general.lineColor;
  theme.chart.background = [
    settings.general.gradient1,
    settings.general.gradient2,
  ];

  setCandleBackground(theme.plot.bar.candle, settings);
  setCandleBackground(theme.plot.bar.candleVolume, settings);
  setCandleBackground(theme.plot.bar.hollowCandle, settings);
  theme.plot.bar.hollowCandle.downHollowCandle.border.strokeColor =
    settings.general.downCandleColor;
  theme.plot.bar.hollowCandle.upHollowCandle.border.strokeColor =
    settings.general.upCandleColor;
  theme.plot.bar.hollowCandle.downHollowCandle.wick.strokeColor =
    settings.general.downCandleColor;
  theme.plot.bar.hollowCandle.upHollowCandle.wick.strokeColor =
    settings.general.upCandleColor;

  setCandleBackground(theme.plot.bar.renko, settings);
  setCandleBackground(theme.plot.bar.lineBreak, settings);
  setCandleBackground(theme.plot.bar.kagi, settings);
  setCandleBackground(theme.plot.bar.equiVolume, settings);
  setCandleBackground(theme.plot.bar.equiVolumeShadow, settings);
  setBarBackground(theme.plot.bar.coloredOHLC, settings);
  setCandleBackground(theme.plot.bar.heikinAshi, settings);
  setCandleBackground(theme.plot.bar.pointAndFigure, settings);

  setBorderColor(theme.plot.bar.candle, settings);
  setBorderColor(theme.plot.bar.candleVolume, settings);
  setBorderColor(theme.plot.bar.equiVolume, settings);
  setBorderColor(theme.plot.bar.equiVolumeShadow, settings);
  setBorderColor(theme.plot.bar.heikinAshi, settings);
  setBorderColor(theme.plot.bar.hollowCandle, settings);
  setBorderColor(theme.plot.bar.lineBreak, settings);

  setWickColor(theme.plot.bar.candle, settings);
  setWickColor(theme.plot.bar.candleVolume, settings);
  setWickColor(theme.plot.bar.hollowCandle, settings);

  theme.valueScale.text.fontFamily = settings.general.font.fontFamily;
  theme.valueScale.text.fillColor = settings.general.font.textColor;
  theme.valueScale.text.fontSize = settings.general.font.fontSize;

  theme.dateScale.text.fontFamily = settings.general.font.fontFamily;
  theme.dateScale.text.fillColor = settings.general.font.textColor;
  theme.dateScale.text.fontSize = settings.general.font.fontSize;

  theme.valueScale.fill.fillColor = settings.general.valueScaleColor;
  theme.dateScale.fill.fillColor = settings.general.dateScaleColor;
  theme.chartPanel.grid.strokeColor = settings.general.gridColor;

  theme.orderSideMarker = settings.trading.ordersColors;
  theme.orderSideMarker.orderBarLength =
    settings.trading.trading.orderBarLength;
  theme.orderSideMarker.orderBarUnit = settings.trading.trading.orderBarUnit;

  theme.tradingPanel.tradingBarLength =
    settings.trading.trading.tradingBarLength;
  theme.tradingPanel.tradingBarUnit = settings.trading.trading.tradingBarUnit;

  theme.positionSideMarker.showPLInfo = settings.trading.trading.showPLInfo;
  theme.positionSideMarker.includeRealizedPL =
    settings.trading.trading.includeRealizedPL;
  theme.positionSideMarker.displayUnit = settings.trading.trading.displayUnit;
  theme.positionSideMarker.positivePLFontColor =
    settings.trading.trading.positivePLFontColor;
  theme.positionSideMarker.positivePLBackgroundColor =
    settings.trading.trading.positivePLBackgroundColor;
  theme.positionSideMarker.negativePLFontColor =
    settings.trading.trading.negativePLFontColor;
  theme.positionSideMarker.negativePLBackgroundColor =
    settings.trading.trading.negativePLBackgroundColor;
  theme.positionSideMarker.positionBarLength =
    settings.trading.trading.orderBarLength;
  theme.positionSideMarker.positionBarUnit =
    settings.trading.trading.orderBarUnit;

  theme.orderChartMarker.showTopBottomMarkers =
    settings.trading.chartMarkers?.showBarMarkerTobBottom;
  theme.orderChartMarker.showMarkerAtExecutionPrice =
    settings.trading.chartMarkers?.showBarMarketExecutionPrice;
  theme.orderChartMarker.showTextWithSizeAndPrice =
    settings.trading.chartMarkers?.showBarTextWithSizePrice;

  theme.orderChartMarker.buy.markerType =
    settings.trading.chartMarkers?.buyMarkerType;
  theme.orderChartMarker.buy.fill.fillColor =
    settings.trading.chartMarkers?.buyMarkerColor;
  theme.orderChartMarker.buy.tooltip.text.fillColor =
    settings.trading.chartMarkers?.buyMarkerColor;
  theme.orderChartMarker.buy.tooltip.text.fontSize =
    settings.trading.chartMarkers?.buyMarkerFontWeight;
  theme.orderChartMarker.buy.tooltip.fill.color =
    settings.trading.chartMarkers?.dataBoxBackgroundColor;

  theme.orderChartMarker.sell.markerType =
    settings.trading.chartMarkers?.sellMarkerType;
  theme.orderChartMarker.sell.fill.fillColor =
    settings.trading.chartMarkers?.sellMarkerColor;
  theme.orderChartMarker.sell.tooltip.text.fillColor =
    settings.trading.chartMarkers?.sellMarkerColor;
  theme.orderChartMarker.sell.tooltip.text.fontSize =
    settings.trading.chartMarkers?.sellMarkerFontWeight;
  theme.orderChartMarker.sell.tooltip.fill.color =
    settings.trading.chartMarkers?.dataBoxBackgroundColor;

  return theme;
}

function setBorderColor(barTheme, settings) {
  barTheme.upCandle.border.strokeColor = settings.general.upCandleBorderColor;
  barTheme.downCandle.border.strokeColor =
    settings.general.downCandleBorderColor;
  barTheme.upCandle.border.strokeEnabled =
    settings.general.upCandleBorderColorEnabled;
  barTheme.downCandle.border.strokeEnabled =
    settings.general.downCandleBorderColorEnabled;
}

function mapSettingsToSideFormState(settings) {
  const orderAreaSettings = settings.trading.orderArea.settings;
  const sideOrderSettings: any = {};
  sideOrderSettings.buyButtonsBackgroundColor =
    orderAreaSettings.buyMarketButton.background;
  sideOrderSettings.buyButtonsFontColor =
    orderAreaSettings.buyMarketButton.font;

  sideOrderSettings.sellButtonsBackgroundColor =
    orderAreaSettings.sellMarketButton.background;
  sideOrderSettings.sellButtonsFontColor =
    orderAreaSettings.sellMarketButton.font;

  sideOrderSettings.flatButtonsBackgroundColor =
    orderAreaSettings.flatten.background;
  sideOrderSettings.flatButtonsFontColor = orderAreaSettings.flatten.font;

  sideOrderSettings.cancelButtonBackgroundColor =
    orderAreaSettings.cancelButton.background;
  sideOrderSettings.cancelButtonFontColor = orderAreaSettings.cancelButton.font;

  sideOrderSettings.closePositionFontColor =
    orderAreaSettings.closePositionButton.font;
  sideOrderSettings.closePositionBackgroundColor =
    orderAreaSettings.closePositionButton.background;

  sideOrderSettings.closePositionFontColor =
    orderAreaSettings.showLiquidateButton?.font;
  sideOrderSettings.closePositionBackgroundColor =
    orderAreaSettings.showLiquidateButton?.background;

  sideOrderSettings.icebergFontColor = orderAreaSettings.icebergButton.font;
  sideOrderSettings.icebergBackgroundColor =
    orderAreaSettings.icebergButton.background;

  sideOrderSettings.orderArea = {};
  sideOrderSettings.orderArea.showPLInfo = true;

  sideOrderSettings.formSettings = {};
  sideOrderSettings.formSettings.showIcebergButton =
    orderAreaSettings.icebergButton.enabled;
  sideOrderSettings.formSettings.showFlattenButton =
    orderAreaSettings.flatten.enabled;
  sideOrderSettings.formSettings.closePositionButton =
    orderAreaSettings.closePositionButton.enabled;
  sideOrderSettings.formSettings.showLiquidateButton =
    orderAreaSettings.showLiquidateButton?.enabled;
  sideOrderSettings.formSettings.showCancelButton =
    orderAreaSettings.cancelButton.enabled;
  sideOrderSettings.formSettings.showBuyButton =
    orderAreaSettings.buyMarketButton.enabled;
  sideOrderSettings.formSettings.showSellButton =
    orderAreaSettings.sellMarketButton.enabled;
  sideOrderSettings.formSettings.showBracket =
    settings.trading.trading.bracketButton;

  sideOrderSettings.formSettings.showPLInfo = true; // settings.trading.trading.showPLInfo;
  sideOrderSettings.tif = settings.trading.tif;

  return sideOrderSettings;
}

function transformPeriodicity(periodicity: string): string {
  switch (periodicity) {
    case '':
      return 'm';
    case 'h':
      return periodicity;
    default:
      return periodicity.toUpperCase();
  }
}
