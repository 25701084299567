import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AppHealthLogger, LogEntry } from './app-health-logger';

interface FormattedLogEntry {
  timestamp: string;
  level: string;
  message: string;
}

@Component({
  selector: 'app-health-logs',
  templateUrl: './app-health-logs.component.html',
  styleUrls: ['./app-health-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHealthLogsComponent implements OnInit, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  @ViewChild('logEntry', { static: false }) logEntryElement: ElementRef;

  itemSize: number = 50; // Default size, will be updated
  formattedLogEntries$: Observable<FormattedLogEntry[]>;
  private logEntriesSubject: BehaviorSubject<LogEntry[]> = new BehaviorSubject<
    LogEntry[]
  >([]);

  constructor(
    private logger: AppHealthLogger,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.logger.getLogEntries().subscribe((entries: LogEntry[]) => {
      this.logEntriesSubject.next(entries);
      this.updateItemSize();
    });
    this.formattedLogEntries$ = this.logEntriesSubject.pipe(
      map((entries: LogEntry[]): FormattedLogEntry[] =>
        entries.map(
          (entry: LogEntry): FormattedLogEntry => ({
            timestamp: this.formatDate(entry.timestamp),
            level: entry.level,
            message: entry.message,
          }),
        ),
      ),
    );
  }

  ngAfterViewInit(): void {
    this.updateItemSize();
  }

  private updateItemSize(): void {
    if (!this.logEntryElement || !this.logEntryElement.nativeElement) {
      return;
    }

    // Helps performance-wise with rendering long lists of logs
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const newSize: number = this.logEntryElement.nativeElement.offsetHeight;
        if (newSize === this.itemSize) {
          return;
        }
        this.itemSize = newSize;
        this.viewport.checkViewportSize();
        this.cdr.detectChanges();
      });
    });
  }

  copyLogsToClipboard(): void {
    this.formattedLogEntries$
      .pipe(take(1))
      .subscribe((entries: FormattedLogEntry[]) => {
        const logText: string = entries
          .map(
            (entry: FormattedLogEntry) =>
              `${entry.timestamp} ${entry.level} ${entry.message}`,
          )
          .join('\n');

        navigator.clipboard.writeText(logText).then(
          (): void => {
            console.log('Logs copied to clipboard');
          },
          (err): void => {
            console.error('Failed to copy logs: ', err);
          },
        );
      });
  }

  eraseLogs(): void {
    this.logger.clearLogs();
  }

  private formatDate(date: Date): string {
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      // @ts-ignore
      fractionalSecondDigits: 3,
    });
  }
}
