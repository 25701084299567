import { Injectable, Injector } from '@angular/core';
import {
  concat,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { NotifierService } from 'notifier';
import { SettlementPrice } from 'projects/communication/src/services/rprotocol/rprotocol-data-transformer';
import { RealSettleDataFeed } from 'real-trading';
import {
  HistoryRepository,
  IAccount,
  IInstrument,
  InstrumentsRepository,
  TradeDataFeed,
  TradePrint,
} from 'trading';

import { Datafeed } from './Datafeed';
import {
  IQuote as ChartQuote,
  IBarsRequest,
  IRequest,
  RequestKind,
} from './models';
import { StockChartXPeriodicity, TimeFrame } from './TimeFrame';

declare let StockChartX: any;

@Injectable()
export class RithmicDatafeed extends Datafeed {
  private _destroy = new Subject();
  lastInterval;

  private _unsubscribeFns: VoidFunction[] = [];
  requestSubscriptions = new Map<number, Subscription>();
  private _deferRequestIntervalId: number;

  private settlementPricesSubject: ReplaySubject<SettlementPrice>;
  public settlementPrices$: Observable<SettlementPrice>;

  constructor(
    protected _injector: Injector,
    private _instrumentsRepository: InstrumentsRepository,
    private _historyRepository: HistoryRepository,
    private _tradeDataFeed: TradeDataFeed,
    private _settleFeed: RealSettleDataFeed,
    private _notifier: NotifierService,
  ) {
    super();
    // Initialize ReplaySubject with a large buffer size to store settlement prices
    this.settlementPricesSubject = new ReplaySubject<SettlementPrice>(1000);
    this.settlementPrices$ = this.settlementPricesSubject.asObservable();
  }

  getSettlementPrices(): Observable<SettlementPrice> {
    return this.settlementPrices$;
  }

  send(request: IBarsRequest) {
    // for omit loading
    if (request?.kind === 'moreBars') return;

    // An account ID is not available yet (ie. user is not connected => no API Key avail), abort the data request
    if (request?.kind === 'bars' && !this._account?.id) {
      return;
    }
    super.send(request);
    this._loadData(request);
  }

  loadInstruments(): Observable<any[]> {
    return this._instrumentsRepository.getItems().pipe(
      tap((instruments) => {
        StockChartX.getAllInstruments = () => instruments.data;
      }),
      map((i) => i.data),
    );
  }

  changeAccount(account: IAccount) {
    const _prevAcc = this._account;
    super.changeAccount(account);
    if (_prevAcc?.id != account?.id) this.subscribeToRealtime();
  }

  changeInstrument(instrument: IInstrument) {
    const _prevInst = this._instrument;
    super.changeInstrument(instrument);
    if (_prevInst?.id != instrument?.id) this.subscribeToRealtime();
  }

  private _normalizePrice(price: number) {
    const tickSize = this._chart.instrument.tickSize;
    return +(Math.round(price / tickSize) * tickSize).toFixed(
      this._chart.instrument?.precision,
    );
  }

  private _roundPrice(price: number) {
    const precision = this._chart.instrument?.precision;
    return Number(
      <string>(
        Math.round(Number.parseFloat(`${price}E${precision}`)).toString()
      ) + <string>`E-${precision}`,
    );
  }

  private _normalizeDate(date: Date, periodicity: string) {
    switch (periodicity) {
      case 'Minute':
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDay(),
          date.getHours(),
          date.getMinutes(),
          0,
          0,
        );
      case 'Hourly':
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDay(),
          date.getHours(),
          0,
          0,
          0,
        );
    }

    return date;
  }

  private _loadData(request: IBarsRequest) {
    const { kind, chart } = request;
    const { instrument, timeFrame } = chart;
    let { startDate, endDate } = request;

    if (!instrument) {
      this.cancel(request);
      return;
    }

    if (this._deferRequestIntervalId) {
      clearInterval(this._deferRequestIntervalId);
    }

    if (kind === 'bars') {
      endDate = new Date();
    }

    if (!endDate) endDate = new Date();

    if (!startDate)
      startDate = new Date(
        endDate.getTime() -
          TimeFrame.timeFrameToTimeInterval(request.chart.periodToLoad),
      );
    startDate.setHours(0, 0, 0, 0);

    this.lastInterval = endDate.getTime() - startDate.getTime();

    if (kind === 'moreBars') {
      startDate = new Date(endDate.getTime() - this.lastInterval);
      this.makeRequest(instrument, request, timeFrame, endDate, startDate);

      return;
    }
    this.subscribeToRealtime(request);
    this.endDate = endDate;
    this.makeRequest(instrument, request, timeFrame, endDate, startDate);
  }

  makeRequest(instrument: IInstrument, request, timeFrame, endDate, startDate) {
    const { exchange, symbol, productCode } = instrument;

    const timeZoneOffset = this._getTimeZone();

    const params = {
      productCode,
      Exchange: exchange,
      Symbol: symbol,
      Periodicity: this._convertPeriodicity(timeFrame.periodicity),
      BarSize: timeFrame.interval,
      endDate,
      accountId: this._account?.id,
      startDate,
      timeZoneOffset,
      PriceHistory: true,
      PriceNormalization: true,
      PriceIncrement: instrument.tickSize,
      PricePrecision: instrument.precision,
    };

    const history$ = this._historyRepository.getItems(params).pipe(
      catchError((err) => {
        this.cancel(request);
        return throwError(err);
      }),
    );

    const subscription = concat(history$).subscribe({
      next: (res) => {
        if (this.isRequestAlive(request)) {
          this.onRequestCompleted(request, {
            bars: res.data,
            additionalInfo: res.additionalInfo,
            periodicity: params.Periodicity,
          });
        }
      },
      error: (err) => {
        this._notifier.showError(err);
        console.error(err);
      },
    });
    this.requestSubscriptions.set(request.id, subscription);
  }

  protected onRequestCompleted(request: IBarsRequest, response) {
    const isChildWindow: boolean = !!(window as any).opener;

    if (this._chart == null) {
      this._chart = request.chart;
      this.subscribeToRealtime(request);
    } else if (isChildWindow) {
      this.subscribeToRealtime(request);
    }

    // if(response?.bars != null) {
    //   response.bars.forEach(b => {
    //     // b.date = this._normalizeDate(b.date, response.periodicity)
    //     b.open = this._normalizePrice(b.open);
    //     b.high = this._normalizePrice(b.high);
    //     b.low = this._normalizePrice(b.low);
    //     b.close = this._normalizePrice(b.close);
    //     b?.details.forEach(d => {
    //       d.price = this._normalizePrice(d.price);
    //     })
    //   });
    // }

    if (response?.bars != null) {
      response.bars.forEach((b) => {
        // b.date = this._normalizeDate(b.date, response.periodicity)
        b.open = this._roundPrice(b.open);
        b.high = this._roundPrice(b.high);
        b.low = this._roundPrice(b.low);
        b.close = this._roundPrice(b.close);
        b?.details.forEach((d) => {
          d.price = this._roundPrice(d.price);
        });
      });
    }

    super.onRequestCompleted(request, response);
    this.requestSubscriptions.delete(request.id);
    if (request.kind === RequestKind.BARS) {
      // Read all settlement prices from the ReplaySubject
      this.getSettlementPrices().subscribe(
        (settlementPrice: SettlementPrice) => {
          // Filter out prices not belonging to the current instrument
          const settlementSymbol: string =
            settlementPrice.instrument?.tradingSymbol ||
            settlementPrice.instrument?.symbol;
          const chartSymbol: string =
            this._chart.instrument.tradingSymbol ||
            this._chart.instrument.symbol;
          if (settlementSymbol !== chartSymbol) return;

          this._chart.dataManager
            .barDataSeries()
            .settled.add(settlementPrice.price);
          // @todo Rename `settled` data series to `settlementPrice` and add a second series named `settlementDates` and keep them in sync
        },
      );
    }
  }

  cancel(request: IRequest) {
    super.cancel(request);
    const subscription = this.requestSubscriptions.get(request.id);
    if (subscription && !subscription.closed) subscription.unsubscribe();
    this.requestSubscriptions.delete(request.id);
  }

  _convertPeriodicity(periodicity: string): string {
    switch (periodicity) {
      case StockChartXPeriodicity.YEAR:
        return 'Yearly';
      case StockChartXPeriodicity.MONTH:
        return 'Monthly';
      case StockChartXPeriodicity.WEEK:
        return 'Weekly';
      case StockChartXPeriodicity.DAY:
        return 'Daily';
      case StockChartXPeriodicity.HOUR:
        return 'Hourly';
      case StockChartXPeriodicity.MINUTE:
        return 'Minute';
      case StockChartXPeriodicity.SECOND:
        return 'Second';
      case StockChartXPeriodicity.REVS:
        return 'REVS';
      case StockChartXPeriodicity.VOLUME:
        return 'VOLUME';
      case StockChartXPeriodicity.RANGE:
        return 'RANGE';
      case StockChartXPeriodicity.RENKO:
        return 'RENKO';
      case StockChartXPeriodicity.TICK:
        return 'TICK';
      default:
        return 'TICK';
    }
  }

  subscribeToRealtime(request?: IBarsRequest) {
    const instrument = this._instrument;
    const account = this._account;
    if (request?.chart && !this._chart) {
      this._chart = request.chart;
    }
    if (!instrument || !account || !this._chart) {
      return;
    }

    this._unsubscribe();
    const connId = this._account?.connectionId;
    if (connId == null) {
      return;
    }

    this._tradeDataFeed.subscribe(instrument, connId);
    this._settleFeed.subscribe(instrument, connId);

    this._unsubscribeFns.push(
      () => this._tradeDataFeed.unsubscribe(instrument, connId),
      () => this._settleFeed.unsubscribe(instrument, connId),
    );

    this._unsubscribeFns.push(
      this._tradeDataFeed.on((trade: TradePrint, connectionId) => {
        if (connectionId !== connId) {
          return;
        }

        const quoteInstrument = trade.instrument;

        if (instrument.id === quoteInstrument.id) {
          const _quote: ChartQuote = {
            // Ask: quote.volume;
            // AskSize: number;
            // Bid: number;
            // BidSize: number;
            instrument: quoteInstrument,
            price: trade.price,
            date: new Date(trade.timestamp),
            volume: trade.volume,
            side: trade.side,
          } as any;
          this.processQuote(request, _quote);
        }
      }),
      this._settleFeed.on((settlementPrice: SettlementPrice): void => {
        this.settlementPricesSubject.next(settlementPrice);
      }),
    );
  }

  private _getInstrument(req: IRequest) {
    return req.instrument ?? req.chart.instrument;
  }

  _unsubscribe() {
    this._unsubscribeFns.forEach((fn) => fn());
    this.requestSubscriptions.forEach((item) => {
      if (item && !item.closed) item.unsubscribe();
    });
    this._unsubscribeFns = [];
  }

  destroy() {
    super.destroy();
    this._destroy.next();
    this._destroy.complete();
    this._unsubscribe();
  }

  private _getTimeZone(): string {
    const offset: number = new Date().getTimezoneOffset();
    const o: number = Math.abs(offset);

    return (
      (offset < 0 ? '' : '-') +
      ('00' + Math.floor(o / 60)).slice(-2) +
      ':' +
      ('00' + (o % 60)).slice(-2) +
      ':00'
    );
  }
}
