import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ArrayHelper, StringHelper } from 'base-components';
import { ILayoutNode, LayoutNode } from 'layout';
import { NotifierService } from 'notifier';
import { DebugFlagService } from 'projects/performance-tools/src/lib/debug-flag.service';
import { SettingsData, SettingsService } from 'settings';
import { ISession } from 'trading';

import { IChart } from '../models';
import {
  BarStats,
  BarStatsDebugSettings,
  CompositeProfile,
  CustomVolumeProfile,
  FootprintSettings,
  General,
  Indicator,
  PriceStats,
  SessionStats,
  SessionStatsDebugSettings,
  VolumeBreakdown,
  VolumeProfile,
  VWAP,
  ZigZag,
  ZigZagOscillator,
} from './indicators';
import {
  IndicatorGroup,
  IndicatorGroupNameEnum,
  TradrrIndicatorEnum,
} from './indicators.model';

declare const StockChartX: any;
declare const Footprint: any;

const EVENTS_SUFFIX = '.scxComponent';

export interface IndicatorsComponent extends ILayoutNode {}

@Component({
  selector: 'indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss'],
})
@LayoutNode()
@UntilDestroy()
export class IndicatorsComponent implements OnInit {
  link: any;
  chart: IChart;

  get indicators(): any[] {
    return (
      (this.chart?.indicators ?? [])
        .filter(
          (i) => i.className !== StockChartX.CustomVolumeProfile.className,
        )
        ?.reverse() || []
    );
  }

  allExpanded = false;
  registeredIndicators = [];
  searchControl = new FormControl('', Validators.required);
  devGroup: IndicatorGroup = {
    name: IndicatorGroupNameEnum.TradrrDebug,
    indicators: ['SessionStatsDebug', 'BarStatsDebug'],
  };
  groups: IndicatorGroup[] = [
    {
      name: IndicatorGroupNameEnum.Tradrr,
      indicators: [
        TradrrIndicatorEnum.BarStats,
        TradrrIndicatorEnum.CompositeProfile,
        TradrrIndicatorEnum.Footprint,
        TradrrIndicatorEnum.PriceStats,
        TradrrIndicatorEnum.SessionStats,
        TradrrIndicatorEnum.VWAP,
        TradrrIndicatorEnum.Volume,
        TradrrIndicatorEnum.VolumeProfile,
        TradrrIndicatorEnum.VolumeBreakdown,
        TradrrIndicatorEnum.ZigZag,
        TradrrIndicatorEnum.ZigZagOscillator,
      ],
      expanded: true,
    },
    {
      name: IndicatorGroupNameEnum.General,
      indicators: [
        'MedianPrice',
        'HML',
        'ROC',
        'VROC',
        'StdDev',
        'WeightedClose',
        'TypicalPrice',
        'SUM',
        'MAX',
        'MIN',
      ],
    },
    {
      name: IndicatorGroupNameEnum.Bands,
      indicators: [
        'Bollinger',
        'HighLowBands',
        'DBox',
        'Ichimoku',
        'PNB',
        'KeltnerChannel',
      ],
    },
    {
      name: IndicatorGroupNameEnum.Index,
      indicators: [
        'ADL',
        'ADX',
        'ASI',
        'SwingIndex',
        'MarketFacilitationIndex',
        'RAVI',
        'ChaikinMoneyFlow',
        'CCI',
        'MFI',
        'RSI',
        'CRS',
        'NVI',
        'EFI',
        'OBV',
        /*'SwingIndex',*/ 'ElderThermometer',
        'PerformanceIndex',
        'TVI',
        'TSI',
        'GRI',
        'PVI',
        /*'PVI',*/ 'TMF',
        'HistoricalVolatility',
        'PVT',
        'IMI' /*'QStick'*/,
      ],
    },
    {
      name: IndicatorGroupNameEnum.MovingAverage,
      indicators: [
        'DeviationToMA',
        'MAEnvelopes',
        'VMA',
        'TMA',
        'EMA',
        'KAMA',
        'HMA',
        'VOLMA',
        'DEMA',
        'TEMA',
        'SMA',
        /*'T3',*/ 'WWS',
        'VIDYA',
        'WMA',
        'ZLEMA',
      ],
    },
    {
      name: IndicatorGroupNameEnum.Oscillator,
      indicators: [
        'AroonOscillator',
        'Aroon',
        'ATR',
        'CenterOfGravity',
        'ChaikinVolatility',
        'MACD',
        'TSF',
        'CFO',
        'FOSC',
        'ChaikinOscillator',
        'CMO',
        'CoppockCurve',
        'PriceOscillator',
        'DM',
        'EasyOfMovement',
        'EFT',
        'ElderRay',
        'TrueRange',
        'TRIX',
        'Stochastics',
        'StochasticsFast',
        'UltimateOscillator',
        'VolumeOscillator',
        'PGO',
        'WillamsR',
        'PPO',
        'WAD',
        'PNO',
      ],
    },
    {
      name: IndicatorGroupNameEnum.Regression,
      indicators: [
        'LinearRegressionForecast',
        'LinearRegression' /*,'TimeSeriesForecast'*/,
        'LinearRegressionIntercept',
        'LinearRegressionSlope',
      ],
    },
    {
      name: IndicatorGroupNameEnum.Others,
      indicators: [
        'ADXR',
        'APZ',
        'BOP',
        'DonchianChannel',
        'RSS',
        'Range',
        'KeyReversalDown',
        'KeyReversalUp',
        'StochRSI',
        'PFE',
        'RIND',
        'Momentum',
        'McGinleysDynamic',
        'VolumeUpDown',
        'NBarsDown',
        'LogChange',
        'NBarsUp',
      ],
    },
  ];
  form: FormGroup;
  formValueChangesSubscription: Subscription;
  indicatorsDescriptions: {
    [key: string]: {
      title: string;
      content: string[];
    }[];
  } = {};
  descriptionEnabled: {
    [key: string]: boolean;
  } = {};

  private _constructorsMap: Map<any, new (...args: any[]) => Indicator>;
  private _forbidAutoScaleIndicators = [];

  constructor(
    private cd: ChangeDetectorRef,
    public _notifier: NotifierService,
    public _settingsService: SettingsService,
    private debugFlagService: DebugFlagService,
  ) {
    if (!this.debugFlagService) {
      return;
    }

    this.debugFlagService.debugMode$.subscribe((isDebugMode: boolean) => {
      if (isDebugMode) {
        this.groups.splice(1, 0, this.devGroup);
        this.clearQuery();
      } else {
        this.groups = this.groups.filter(
          (group: any) => group.name !== this.devGroup.name,
        );
      }
    });
  }

  ngOnInit(): void {
    this.setTabTitle('Indicators');
    this.groups = this.groups.map((item) => {
      item.filteredIndicators = item.indicators;
      return item;
    });
    this.searchControl.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((query) => this.search(query));
    this._notifier.customObservable.subscribe((res) => {
      this.Loadindicators(res, 'PriceStats');
    });

    this.preselectPreviousIndicator();

    this._settingsService.settings
      .pipe(take(1))
      .subscribe((settings: SettingsData): void => {
        this._toggleIndicatorAvailability(
          TradrrIndicatorEnum.ZigZag,
          settings?.admin?.betaFeatures?.chartIndicators?.zigZag,
        );
        this._toggleIndicatorAvailability(
          TradrrIndicatorEnum.ZigZagOscillator,
          settings?.admin?.betaFeatures?.chartIndicators?.zigZagOscillator,
        );
      });
    this._settingsService.zigZagIndicatorChange$
      .pipe(untilDestroyed(this))
      .subscribe((isZigZagIndicatorEnabled: boolean): void => {
        this._toggleIndicatorAvailability(
          TradrrIndicatorEnum.ZigZag,
          isZigZagIndicatorEnabled,
        );
      });
    this._settingsService.zigZagOscillatorIndicatorChange$
      .pipe(untilDestroyed(this))
      .subscribe((isZigZagOscillatorIndicatorEnabled: boolean): void => {
        this._toggleIndicatorAvailability(
          TradrrIndicatorEnum.ZigZagOscillator,
          isZigZagOscillatorIndicatorEnabled,
        );
      });
  }

  /**
   * If there is a previously selected indicator on the current chart, load its settings by default
   * @returns {void}
   */
  preselectPreviousIndicator(): void {
    if (!this._notifier.selectedIndicator) {
      return;
    }

    const isIndicatorOnCurrentChart: boolean =
      this._notifier.selectedIndicator?.instance?._chart === this.chart;

    if (!isIndicatorOnCurrentChart) {
      // Previously selected indicator does not belong to the current chart, so remove its reference
      this._notifier.selectedIndicator = null;
      return;
    }

    this.Loadindicators(
      this._notifier.selectedIndicator,
      this._notifier.selectedIndicator.name,
    );
  }

  isSelected(item: any) {
    return this._notifier.selectedIndicator?.instance === item;
  }

  selectIndicator(item: any) {
    const priceStat = 'PriceStats';
    this._notifier.priceStat = item._name;
    const _constructor = this._constructorsMap.get(item.className) || General;
    this._notifier.selectedIndicator = new _constructor(item);
    if (this._notifier.periodInterval < 3 && item._name == priceStat) {
      this._notifier.setDisabled(true, true);
    } else if (item._name == priceStat) {
      this._notifier.setDisabled(false, true);
    }
    this.Loadindicators(this._notifier.selectedIndicator, item._name);
  }

  Loadindicators(res: any, name: string) {
    this._notifier.selectedIndicator = res;
    this.formValueChangesSubscription?.unsubscribe();
    this.form = new FormGroup({});
    this.form.valueChanges
      .pipe(debounceTime(10), untilDestroyed(this))
      .subscribe((): void => {
        this._notifier.selectedIndicator.applySettings(
          this._notifier.selectedIndicator.settings,
        );
        this.chart.updateIndicators();
        const autoScale = !this._forbidAutoScaleIndicators.includes(
          this._notifier.selectedIndicator.instance.className,
        );
        this.chart.setNeedsLayout();
        this.chart.setNeedsUpdate();
      });
  }
  loadState(state?: any) {
    this.link = state?.link;
    this.chart = state?.chart;

    this._handleChart(this.chart);

    this.addLinkObserver({
      link: this.link,
      layoutContainer: this.layoutContainer,
      handleLinkData: (chart: IChart) => {
        this.chart = chart;
        this._handleChart(this.chart);
      },
    });
  }

  saveState() {
    return {
      link: this.link,
    };
  }

  fetchIndicators() {
    this.registeredIndicators = StockChartX.Indicator.registeredIndicators;
  }

  addIndicator(item) {
    if (this.indicators.find((i) => i._name === item)) return;

    // TODO read this from backend rather than hardcoding it
    // Default session config for VolumeProfile
    const defaultSessionConfig: ISession = {
      id: 0,
      name: 'US Extended Trading Hours',
      exchange: 'CME',
      timezoneId: 'America/New_York',
      workingTimes: [
        {
          startDay: 0,
          startTime: 64800000,
          endDay: 1,
          endTime: 61200000,
          tradingDay: 1,
        },
        {
          startDay: 1,
          startTime: 64800000,
          endDay: 2,
          endTime: 61200000,
          tradingDay: 2,
        },
        {
          startDay: 2,
          startTime: 64800000,
          endDay: 3,
          endTime: 61200000,
          tradingDay: 3,
        },
        {
          startDay: 3,
          startTime: 64800000,
          endDay: 4,
          endTime: 61200000,
          tradingDay: 4,
        },
        {
          startDay: 4,
          startTime: 64800000,
          endDay: 5,
          endTime: 61200000,
          tradingDay: 5,
        },
      ],
    };

    let indicatorConfig = {};
    if (item === 'VolumeProfile') {
      indicatorConfig = { ...indicatorConfig, defaultSessionConfig };
    }

    const _constructor = this.registeredIndicators[item];
    const indicator = new _constructor(indicatorConfig);
    if (item === 'ZigZag') {
      if (indicator._settings.dataBox.fields.delta != undefined) {
        indicator._settings.dataBox.fields.delta.enabled = false;
      }
      if (indicator._settings.dataBox.fields.volume != undefined) {
        indicator._settings.dataBox.fields.volume.enabled = false;
      }
      if (indicator._settings.parameters.dataBoxOffset != undefined) {
        indicator._settings.parameters.dataBoxOffset = 10;
      }
      if (indicator._settings.parameters.showZigZagLine != undefined) {
        indicator._settings.parameters.showZigZagLine = false;
      }

      if (indicator._settings.parameters.showRealtimeSwing != undefined) {
        indicator._settings.parameters.showRealtimeSwing = false;
      }
      if (indicator._settings.parameters.dataBoxBackground.value != undefined) {
        indicator._settings.parameters.dataBoxBackground.value = '#3F3F3F';
      }
      if (indicator._settings.parameters.dataBoxBorder.value != undefined) {
        indicator._settings.parameters.dataBoxBorder.value = '#000000';
      }
    }
    this.chart.addIndicators(indicator);
    this._applyZIndex();
    this.chart.setNeedsUpdate();
    this.chart.setNeedsLayout();
    this.selectIndicator(indicator);
  }

  private _handleChart(chart: IChart) {
    if (!chart) return;

    this._constructorsMap = new Map<any, new (...args: any[]) => Indicator>([
      [StockChartX.Footprint.className, FootprintSettings],
      [StockChartX.VolumeProfile.className, VolumeProfile],
      [StockChartX.CompositeProfile.className, CompositeProfile],
      [StockChartX.PriceStats.className, PriceStats],
      [StockChartX.SessionStats.className, SessionStats],
      [StockChartX.SessionStatsDebug.className, SessionStatsDebugSettings],
      [StockChartX.BarStatsDebug.className, BarStatsDebugSettings],
      [StockChartX.VolumeBreakdown.className, VolumeBreakdown],
      [StockChartX.ZigZag.className, ZigZag],
      [StockChartX.ZigZagOscillator.className, ZigZagOscillator],
      [StockChartX.BarStats.className, BarStats],
      [StockChartX.VWAP.className, VWAP],
      [StockChartX.CustomVolumeProfile.className, CustomVolumeProfile],
    ]);
    this._forbidAutoScaleIndicators = [StockChartX.ZigZagOscillator.className];

    this.fetchIndicators();
  }

  removeIndicator(item: any): void {
    const { chart } = this;
    if (this._notifier.selectedIndicator?.instance === item)
      this._notifier.selectedIndicator = null;

    this._applyZIndex();
    chart.removeIndicators(item);
    chart.setNeedsUpdate();
  }

  toggleVisible(item) {
    item.visible = !item.visible;
    const { chart } = this;
    chart.setNeedsUpdate();
    chart.setNeedsLayout();
  }

  removeAll() {
    const { chart } = this;

    this.indicators.forEach((item) => {
      chart.removeIndicators(item);
    });
    chart.setNeedsUpdate();
  }

  expand(group) {
    group.expanded = !group.expanded;
    this.allExpanded = this.groups.every((item) => item.expanded);
  }

  search(query: string) {
    const isEmpty = query === '' || query != null;
    this.groups = this.groups.map((item) => {
      if (isEmpty)
        item.filteredIndicators = item.indicators.filter((indicator) =>
          indicator.toLowerCase().includes(query.toLowerCase()),
        );
      else item.filteredIndicators = item.indicators;
      return item;
    });
  }

  fetchDescription(name: string) {
    if (this.indicatorsDescriptions.hasOwnProperty(name)) {
      return;
    }
    const keys = ['overview', 'interpretation', 'parameters'];
    const contentKeys = ['content', 'content1', 'content2'];

    const promises = keys.map((key) => {
      const contentPromises = contentKeys.map((contentKey) => {
        if (!StockChartX) return Promise.reject();
        return StockChartX.Localization.localizeText(
          this.chart,
          `indicator.${name}.help.${key}.${contentKey}`,
          { defaultValue: null },
        );
      });

      return Promise.all(contentPromises).then((content) =>
        content.filter(Boolean),
      );
    });

    Promise.all(promises).then((sections) => {
      this.indicatorsDescriptions[name] = sections.map((content, i) => ({
        title: StringHelper.capitalize(keys[i]),
        content,
      }));
      this.descriptionEnabled[name] = sections.some((item) => item.length);
      this.cd.detectChanges();
    });
  }

  clearQuery() {
    this.searchControl.patchValue('');
  }

  toggleAll() {
    this.groups = this.groups.map((item) => ({
      ...item,
      expanded: !this.allExpanded,
    }));
    this.allExpanded = !this.allExpanded;
  }

  dropped({ previousIndex, currentIndex }) {
    const indicators = this.indicators;
    ArrayHelper.shiftItems(indicators, previousIndex, currentIndex);
    this._applyZIndex(indicators);
    this.chart.setNeedsLayout();
    this.chart.setNeedsUpdate();
  }

  private _applyZIndex(indicators = this.indicators) {
    for (let i = 0; i < indicators.length; i++) {
      if (!indicators[i]) continue;

      indicators[i].zIndex = 1000 - i;
    }
  }

  private _toggleIndicatorAvailability(
    indicatorName: TradrrIndicatorEnum,
    isIndicatorEnabled: boolean,
  ): void {
    const indicator: any = this.indicators.find(
      (indicator: any): boolean => indicator._name === indicatorName,
    );
    let tradrrIndicatorsGroup: string[] = this.groups.find(
      (indicatorGroup: IndicatorGroup): boolean =>
        indicatorGroup.name === IndicatorGroupNameEnum.Tradrr,
    ).indicators;

    if (indicator && !isIndicatorEnabled) {
      this.removeIndicator(indicator);
    }

    if (isIndicatorEnabled && !tradrrIndicatorsGroup.includes(indicatorName)) {
      tradrrIndicatorsGroup.push(indicatorName);
    } else if (!isIndicatorEnabled) {
      tradrrIndicatorsGroup.splice(
        tradrrIndicatorsGroup.indexOf(indicatorName),
        1,
      );
    }

    tradrrIndicatorsGroup = tradrrIndicatorsGroup.sort();
  }
}
