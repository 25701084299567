import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentStore } from 'lazy-modules';
import {
  NzButtonModule,
  NzCardModule,
  NzCollapseModule,
  NzGridModule,
  NzToolTipModule,
} from 'ng-zorro-antd';
import { WindowHeaderModule } from 'window-header';

import { AppHealthLogsComponent } from './app-health-logs/app-health-logs.component';
import { BrowserThrottlingDetectorComponent } from './browser-throttling/browser-throttling-detector/browser-throttling-detector.component';
import { PerformanceMetricsToolbarComponent } from './performance-metrics-toolbar/performance-metrics-toolbar.component';
import { PerformanceMetricsComponent } from './performance-metrics/performance-metrics.component';
import { PerformanceToolsComponent } from './performance-tools.component';
import { UserIdleDetectorComponent } from './user-idle-detector/user-idle-detector.component';
import { VersionInfoComponent } from './version-info/version-info.component';

@NgModule({
  declarations: [
    PerformanceToolsComponent,
    PerformanceMetricsToolbarComponent,
    PerformanceMetricsComponent,
    VersionInfoComponent,
    AppHealthLogsComponent,
    UserIdleDetectorComponent,
    BrowserThrottlingDetectorComponent,
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    WindowHeaderModule,
    NzGridModule,
    NzCardModule,
    ScrollingModule,
    NzCollapseModule,
    NzToolTipModule,
  ],
  exports: [
    PerformanceToolsComponent,
    PerformanceMetricsToolbarComponent,
    PerformanceMetricsComponent,
    VersionInfoComponent,
  ],
})
export class PerformanceToolsModule {
  get components(): ComponentStore {
    return {
      'performance-tools': PerformanceToolsComponent,
      'version-info': VersionInfoComponent,
    };
  }
}
